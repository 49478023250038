// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$gray: #383832;
// $lightGray: #312E25;
$lightGray: #45443D;

$orange: #F27401;
$white: #FFFFFF;
$red: #DF1716;
$blue: #274ED9;
$green: #0A7B15;
$brown: #6D3E13;
$pink: #FF11BC;

$lumber: #386837;
$brick: #C86126;
$wool: #94D054;
$grain: #EED800;
$ore: #535248;
$cloth: #94D054;
$coin: #535248;
$paper: #386837;
$goldmine: #CFBC79;

$textCloth: #DDFFB2;
$textCoin: #AC9A76;
$textPaper: #DAF48F;

$desert: #C6C093;
$water: #374958;

$yellow: #EED800;
