// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.card {
    background-color: $gray;
}

body {
    background: $gray;
    color: $white;
    user-select: none;
}

label {
    font-size: 12px;
}

.opponents-wrapper {
    position: fixed;
    overflow-y: scroll;
    // height: 100vh;
    height: 100%;
    direction: rtl;
    top: 0;
    left: 0px;
    padding-left: 16px;
    // background: rgba(255, 120, 255, 0.8);
    .opponent-wrapper {
        direction:ltr;
    }
}

h1, h2, h3, p, small {
    font-family: 'Montserrat', sans-serif;
}
.transparent-modal {
    opacity: 0.95;
}

.mining-irrigation-count {
    position: absolute;
    text-align: right;
    top: 2px;
    right: 4px;
    color: $textCoin;
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 11px;
}

.show-modal-again {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: $gray;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 999;
    text-align: center;
    line-height: 44px;
    color: $textCoin;
    border: 3px solid $textCoin;
}

.uppercase {
    text-transform: uppercase;
}

.letter-spacing {
    letter-spacing: 1px;
}

.btn {
    color: $textCoin;
    background: $gray;
    border: 2px solid $textCoin;
    border-radius: 0;
    margin-right: 6px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    padding: 6px 14px;
    &:hover {
        color: $textCoin;
        background: $lightGray;
    }
}

#modal, .counterOfferModal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: $gray;
    min-width: 312px;
    min-height: 192px;
    padding: 42px 42px;
    padding-top: 30px;
    z-index: 99999999;

    .alchemist-not-selected {
        opacity: 0.5;
    }

    .head {
        border-bottom: 2px solid $textCoin;
        height: 32px;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        color: $textCoin;
        padding-right: 50px;
        .hide-modal {
            position: absolute;
            right: 0;
            top: -4px;
            width: 27px;
            height: 27px;
            background: $textCoin;
            color: $gray;
            border-radius: 50%;
            font-size: 12px;
            padding-left: 7px;
            padding-top: 5px;
        }
        .close-modal {
            position: absolute;
            right: 0;
            top: 0;
            width: 22px;
            height: 22px;
            cursor: pointer;
            .line {
                width: 16px;
                height: 1px;
                background-color: $textCoin;
                position: absolute;
                top: 10px;
                right: 0px;
            }
            .line-1 {
                transform: rotate(45deg);
            }
            .line-2 {
                transform: rotate(-45deg);
            }
            &:hover .line {
                width: 18px;
                top: 10px;
                right: -1px;
            }
        }
    }
}

.modal-handcards {
    float: left;
    min-height: 100px;
}

html {
    height: 100%;
}

// .city-in-forest {
//     background-image: url('/images/illustrations/city-in-forest.png');
//     background-size: 60%;
//     background-repeat: no-repeat;
//     background-position: left;
//     padding-bottom: 500px;
//     a {
//         background-color: $gray;
//         padding: 0px 4px;
//     }
// }

label {
    background-color: $gray;
    padding: 0px 4px;
}

.rolling-dices {
    &:hover {
        .dice.white {
            transition: .5s ease-in-out;
            transform: scale(1.00) rotate(-185deg);
        }
        .dice.red {
            transition: .5s ease-in-out;
            transform: scale(1.00) rotate(185deg);
        }
    }
}

.rolling-dices-boat {
    &:hover {
        .dice.white {
            transition: .5s ease-in-out;
            transform: scale(1.00) rotate(-185deg);
        }
        .dice.dice-boat {
            transition: .5s ease-in-out;
            transform: scale(1.00) rotate(185deg);
        }
    }
}

.dice-wrapper {
    position: fixed;
    // bottom: 312px;
    // bottom: 327px;
    bottom: 315px;
    right: 34px;
}

.dice, .small-dice {
    width: 38px;
    height: 38px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: repeat;
    float: left;
    margin-left: 8px;
    transition: transform .2s ease-out;
    border: 1px solid $gray;
    background-position: 0px 0px;
    &.white {
        background-image: url('/images/pieces/dice-white.svg');
    }
    &.red {
        background-image: url('/images/pieces/dice-red.svg');
    }
    &.dice-boat {
        background-size: 200%;
        background-image: url('/images/pieces/dice-boat.svg');
    }
    &.dice-boat-2 {
        background-size: 200%;
        background-image: url('/images/pieces/dice-boat-2.png');
    }
    &.rolling {
        animation: slide 0.4s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.upsideDown {
    transition: transform .0s ease-out;
    transform: rotate(-180deg);
}

@keyframes slide {
  0% { background-position: 0px 0px; }
  100% { background-position: 0px -216px; }
}

.small-dice {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-size: cover;
    background-repeat: repeat;
    float: left;
    margin-left: 3px;
    transition: transform .2s ease-out;
    border: 1px solid $gray;
    background-position: 0px 0px;
    &.empty {
        background: $gray;
        border-color: #666;
    }
}

.war-boat-tile-mobile {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 200px;
    height: 100px;
    background: $gray;
    z-index: 9999;
}

.war-boat-mobile {
    width: 50px;
    height: 38px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/images/pieces/boat.svg');
    transition: all 1s ease-out;
}

.war-boat-tile {
    background-image: url('/images/tiles/boat.svg');
    background-size: contain;
    width: 210px;
    height: 210px;
    position: fixed;
    top: 110px;
    right: 60px;
    background-repeat: no-repeat;
    .war-boat {
        width: 50px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('/images/pieces/boat.svg');
        position: absolute;
        transition: all 1s ease-out;
        &.position-0 {
            left: 81px;
            top: -24px;
        }
        &.position-1 {
            left: 151px;
            top: 5px;
        }
        &.position-2 {
            left: 180px;
            top: 75px;
        }
        &.position-3 {
            left: 150px;
            top: 146px;
            transform: scaleX(-1);
        }
        &.position-4 {
            left: 80px;
            top: 176px;
            transform: scaleX(-1);
        }
        &.position-5 {
            left: 13px;
            top: 146px;
            transform: scaleX(-1);
        }
        &.position-6 {
            left: -19px;
            top: 91px;
        }
        &.position-7 {
            left: 11px;
            top: 5px;
        }
    }
}

.mirrored {
    transform: scaleX(-1);
}

.tile-svg {
    position: relative;
    top: -17px;
}

.editor-tile-svg {
    position: relative;
    top: -12px;
}

.tile-clicked {
    position: absolute;
    z-index: 3;
    width: 150px;
    height: 150px;
    // background-size: 146px 146px;
    background-size: 148px 148px;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    color: $white;
    font-family: 'Inter', sans-serif;
    user-select: none;
}

.tile {
    position: absolute;
    width: 150px;
    height: 150px;
    // background-size: 146px 146px;
    background-size: 148px 148px;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    color: $white;
    font-family: 'Inter', sans-serif;
    user-select: none;

    &.goldmine { background-image: url('/images/tiles/goldmine.png'); }
    // &.empty { background-image: url('/images/tiles/empty.png'); background-size: 142px 142px; }
    &.no-initial-build { background-image: url('/images/tiles/no-initial-build.png'); background-size: 140px 140px; }
    &.hidden-tile, &.build-blocked, &.artist { background-image: url('/images/tiles/empty.png'); }
    &.random-resource { background-image: url('/images/tiles/random-resource.png'); background-size: 142px 142px; }
    &.random-resource-gold { background-image: url('/images/tiles/random-resource-gold.png'); background-size: 142px 142px; }
    &.surprise-me { background-image: url('/images/tiles/surprise-me.png'); background-size: 142px 142px; }
    &.random-resource-no-desert { background-image: url('/images/tiles/random-resource-no-desert.png'); background-size: 142px 142px; }
    &.random-harbor { background-image: url('/images/tiles/water.png'); }

    // &.lumber { background-image: url('/images/tiles/lumber.png'); }
    &.lumber-1 { background-image: url('/images/tiles/lumber-1.png'); }
    &.lumber-2 { background-image: url('/images/tiles/lumber-2.png'); }
    &.lumber-3 { background-image: url('/images/tiles/lumber-3.png'); }
    &.lumber-4 { background-image: url('/images/tiles/lumber-4.png'); }
    &.wool-1 { background-image: url('/images/tiles/wool-1.png'); }
    &.wool-2 { background-image: url('/images/tiles/wool-2.png'); }
    &.wool-3 { background-image: url('/images/tiles/wool-3.png'); }
    &.wool-4 { background-image: url('/images/tiles/wool-4.png'); }
    // &.brick { background-image: url('/images/tiles/brick.png'); }
    // &.wool { background-image: url('/images/tiles/wool.png'); }
    // &.grain { background-image: url('/images/tiles/grain.png'); }
    // &.ore { background-image: url('/images/tiles/ore.png'); }

    // &.desert { background-image: url('/images/tiles/desert.png'); }
    // &.water { background-image: url('/images/tiles/water.png'); }

    // &.goldmine { background-image: url('/images/tiles/goldmine.png'); }
    // &.empty { background-image: url('/images/tiles/empty.png'); background-size: 142px 142px; }
    // &.no-initial-build { background-image: url('/images/tiles/no-initial-build.png'); background-size: 140px 140px; }
    // &.hidden-tile, &.build-blocked { background-image: url('/images/tiles/empty.png'); }
    // &.random-resource { background-image: url('/images/tiles/random-resource.png'); background-size: 142px 142px; }
    // &.random-resource-gold { background-image: url('/images/tiles/random-resource-gold.png'); background-size: 142px 142px; }
    // &.surprise-me { background-image: url('/images/tiles/surprise-me.png'); background-size: 142px 142px; }
    // &.random-resource-no-desert { background-image: url('/images/tiles/random-resource-no-desert.png'); background-size: 142px 142px; }
    // &.random-harbor { background-image: url('/images/tiles/random-harbor.png'); }

    // &.harbor-lumber { background-image: url('/images/tiles/harbor-lumber.png'); }
    // &.harbor-brick { background-image: url('/images/tiles/harbor-brick.png'); }
    // &.harbor-wool { background-image: url('/images/tiles/harbor-wool.png'); }
    // &.harbor-grain { background-image: url('/images/tiles/harbor-grain.png'); }
    // &.harbor-ore { background-image: url('/images/tiles/harbor-ore.png'); }
    // &.harbor-all { background-image: url('/images/tiles/harbor-all.png'); }

    // &.lumber .tile-number.selected { border-color: #DAF48F }
    // &.brick .tile-number.selected { border-color: #FF8515 }
    // &.wool .tile-number.selected { border-color: #DDFFB2 }
    // &.grain .tile-number.selected { border-color: #FFFFFF }
    // &.ore .tile-number.selected { border-color: #AC9A76 }

    .tile-number {
        .numberline {
            position: relative;
            top: -17px;
            width: 27px;
            height: 5.5px;
            background: #2d2a27;
            margin: 0 auto;
            border-radius: 4px;
        }
        width: 55px;
        height: 30px;
        // background-color: #312E25;
        // background-color: #2e2c29;
        // background-color: #ffffff45;
        // border: 1px dashed #ffffff65;
        font-weight: 500;
        // color: #AC9A76;
        color: #2d2a27;
        // color: #000;
        position: absolute;
        // line-height: 28px;
        // padding-right: 8px;
        // border-radius: 30px;
        // top: 36px;
        top: 51px;
        // left: 48px;
        left: 49px;
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        border: 2px solid transparent;
        // border: 1px solid #00000020;

        &.red-number {
            font-weight: 600;
            // color: #ED000E;
            // color: #ff0000;
            // color: #E70A0A;
            // color: #ea0665;
        }
        &.red-number-lumber { color: #bbd571; }
        &.red-number-brick { color: #ffa63c; }
        &.red-number-wool { color: #DDFFB2; }
        &.red-number-grain { color: #f8fDb6; }
        &.red-number-ore { color: #AC9A76; }
        &.red-number-goldmine { color: #ffe988; }

        &.red-number-lumber .numberline { border: 1.5px solid $lumber; background: #bbd571; }
        &.red-number-brick .numberline { border: 1.5px solid $brick; background: #ffa63c; }
        &.red-number-wool .numberline { border: 1.5px solid $wool; background: #DDFFB2; }
        &.red-number-grain .numberline { border: 1.5px solid $grain; background: #f8fDb6; }
        &.red-number-ore .numberline { border: 1.5px solid $ore; background: #AC9A76; }
        &.red-number-goldmine .numberline { border: 1.5px solid $goldmine; background: #ffe988; }

        &.big-number {
            // font-size: 28px !important;
            font-size: 32px !important;
            // top: 33px;
            top: 47px;
        }
        &.bold-number {
            font-weight: 500;
        }
        &.small-number {
            font-size: 19px !important;
            // top: 43px;
            top: 58px;
        }
        &.highlighted {
            animation: highlightTileNumberAnimation 3s;
            animation-iteration-count: infinite;
            animation-timing-function: ease;
        }
        &.selected {
            // border: 2px solid #FFF;
            animation: none;
            // transform: scale(1.25) !important;
            // box-shadow: 16px -9px 0px -4px #3838321c;
            // color: #FFF;
            height: 54px;
            // background: #FFF;
            border-radius: 54px;
            line-height: 51px;
            width: 54px;
            margin-top: -2px;
        }
    }

    .tile-number-dark {
        // background-color: #00000020;
        // border: 1px dashed #00000030;
    }

    &.lumber .tile-number.selected { background: #bbd571; }
    &.brick .tile-number.selected { background: #ffa63c }
    &.wool .tile-number.selected { background: #DDFFB2 }
    &.grain .tile-number.selected { background: #f8fDb6 }
    &.ore .tile-number.selected { background: #AC9A76 }
    &.goldmine .tile-number.selected { background: #ffe988 }
}

.preview-tile {
    position: absolute;
    width: 52px;
    height: 52px;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    color: $white;
    font-family: 'Inter', sans-serif;
    user-select: none;

    &.lumber { background-image: url('/images/tiles/lumber.png'); }
    &.brick { background-image: url('/images/tiles/brick.png'); }
    &.wool { background-image: url('/images/tiles/wool.png'); }
    &.grain { background-image: url('/images/tiles/grain.png'); }
    &.ore { background-image: url('/images/tiles/ore.png'); }

    &.desert { background-image: url('/images/tiles/desert.png'); }
    &.water, &.harbor-lumber, &.harbor-brick, &.harbor-wool, &.harbor-grain, &.harbor-ore, &.random-harbor, &.harbor-all { background-image: url('/images/tiles/water.png'); }

    &.hidden-tile, &.build-blocked, &.artist { background-image: url('/images/tiles/empty.png'); }
    &.goldmine { background-image: url('/images/tiles/goldmine.png'); }
    &.random-resource { background-image: url('/images/tiles/empty.png'); }
    &.random-resource-gold { background-image: url('/images/tiles/empty.png'); }
    &.surprise-me { background-image: url('/images/tiles/empty.png'); }
    &.random-resource-no-desert { background-image: url('/images/tiles/empty.png'); }

    .tile-number {
        width: 41px;
        height: 41px;
        background-color: #312E25;
        position: absolute;
        line-height: 38px;
        border-radius: 50%;
        top: 56px;
        left: 55px;
        text-align: center;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        border: 2px solid transparent;

        &.big-number {
            font-size: 14px !important;
        }
        &.bold-number {
            font-weight: 500;
        }
        &.small-number {
            font-size: 9px !important;
        }
        &.highlighted {
            animation: highlightTileNumberAnimation 3s;
            animation-iteration-count: infinite;
            animation-timing-function: ease;
        }
        &.selected {
            border: 2px solid #FFF;
            animation: none;
            transform: scale(1.25) !important;
            box-shadow: 16px -9px 0px -4px #3838321c;
        }
    }
}

.animated-dots-wrapper {
    overflow: hidden;
    width: 0px;
    animation: animatedDots 1.4s infinite;
    animation-timing-function: linear;
    position: relative;
    top: 5px;
    left: 0px;
    display: inline-block;
    margin-top: -5px;
}

@keyframes animatedDots {
    0% { width: 0px; }
    24% { width: 0px; }
    25% { width: 8px; }
    49% { width: 8px; }
    50% { width: 16px; }
    74% { width: 16px; }
    75% { width: 24px; }
    100% { width: 24px; }
}

.opacity-30 {
    opacity: .3;
}

.red-number {
    color: #ff0051;
}

@keyframes highlightTileNumberAnimation {
  0% { transform: scale(0.95); }
  25% { transform: scale(1.05); }
  50% { transform: scale(0.95); }
  75% { transform: scale(1.05); }
  100% { transform: scale(0.95); }
}

.actionTile {
    position: absolute;
    width: 150px;
    height: 150px;
    color: $white;
    font-family: 'Montserrat', sans-serif;
}

.spot {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    position: absolute;
    z-index: 4;
    .inner {
        transition: all .12s ease-in-out;
        width: 0px;
        height: 0px;
        margin:  5px auto;
        border-radius: 50%;
        margin-top: 12px;
    }
    &:hover .inner {
        margin-top: 4px;
        width: 14px;
        height: 14px;
        background: $gray;
    }
    &:hover .empty-highlighted-spot {
        background-color: $gray;
    }
}

.road-spot {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    .inner {
        transition: all .3s ease-in-out;
        width: 0px;
        height: 0px;
        margin:  5px auto;
        margin-top: 12px;
    }
    .inner-hover {
        transition: all .3s ease-in-out;
        width: 0px;
        height: 0px;
    }
    &:hover .inner-hover {
        margin-top: 5px;
        width: 10px;
        height: 10px;
        background: $gray;
    }
    .highlighted-road-spot-empty {
        animation: highlightedRoadSpotEmptyAnimation 2.2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        margin-top: 5px;
        width: 10px;
        height: 10px;
        background: $gray;
        z-index: 99;
    }
}

.robber {
    width: 26px;
    height: 46px;
    background-image: url('/images/pieces/robber.svg');
    position: absolute;
    // top: 39px;
    top: 44px;
    left: 63px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 4;
    .robber-number {
        user-select: none;
        cursor: default;
        text-align: center;
        position: relative;
        // font-family: 'Montserrat', sans-serif;
        font-family: 'IBM Plex Mono', monospace !important;
        top: 15px;
        color: $gray;
        font-size: 12px;
        &.red-number {
            color: #ED000E;
        }
        &.big-number {
            font-size: 14px !important;
        }
        &.bold-number {
            font-weight: 500;
        }
        &.medium-number {
            top: 18px;
            font-size: 10px !important;
        }
        &.small-number {
            top: 18px;
            font-size: 9px !important;
        }
    }
    .talking {
        opacity: 0;
        font-size: 8px;
        width: 20px;
        height: 15px;
        text-align: center;
        background-image: url(/images/pieces/robber-talking.png);
        position: absolute;
        top: -5px;
        left: 26px;
        background-size: contain;
        background-repeat: no-repeat;
        line-height: 15px;
        transition: all .3s ease-in-out;
        user-select: none;
    }
    &.jumping {
        animation: robberJumping 2.2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
    &.robber-next-to-merchant {
        left: 40px;
    }
    &.aside {
        left: 103px;
    }
}
.pirate {
    width: 36px;
    height: 42px;
    background-image: url('/images/pieces/pirate.svg');
    position: absolute;
    top: 53px;
    left: 57px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 4;
    &.spinning {
        animation: pirateSpinning 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.desert .robber {
    top: 46px;
}

.merchant-desert {
    width: 26px;
    height: 46px;
    background-image: url('/images/pieces/merchant.png');
    position: absolute;
    top: 54px;
    left: 62px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
    &.merchant-next-to-robber {
        left: 84px;
    }
}

.merchant-desert-shadow {
    background-image: url('/images/pieces/merchant-shadow.png');
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 22px;
    top: 65px;
    left: 70px;
    // left: 44px;
    transform: scaleX(1);
    &.merchant-next-to-robber {
        left: 94px;
    }
}
.robber-shadow {
    background-image: url('/images/pieces/robber-shadow.png');
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 29px;
    height: 30px;
    z-index: 1;
    top: 58px;
    left: 82px;
    // left: 40px;
    transform: scaleX(1);
    &.robber-next-to-merchant {
        left: 61px;
    }
}

@keyframes robberJumping {
  0% { transform: scale(1.1); top: 34px; }
  15% { transform: scale(1); top: 40px }
  30% { transform: scale(1); top: 40px }
  50% { transform: scale(1.1); top: 34px;  }
  65% { transform: scale(1); top: 40px }
  80% { transform: scale(1); top: 40px }
  100% { transform: scale(1.1); top: 34px;  }
}

@keyframes pirateSpinning {
    0% { transform: rotate(0deg) scale(-1) }
    100% { transform: rotate(360deg) scale(-1) }
  }

.robber:hover .talking {
    opacity: 1;
}

.robber-cursor:hover {
    cursor: url("/images/cursors/robber-1x.png") 15 50, pointer;
    cursor: image-set( "/images/cursors/robber-1x.png" 1x, "/images/cursors/robber-2x.png" 2x) 15 50, pointer;
}

.pirate-cursor:hover {
    cursor: url("/images/cursors/pirate-1x.png") 15 50, pointer;
    cursor: image-set( "/images/cursors/pirate-1x.png" 1x, "/images/cursors/pirate-2x.png" 2x) 15 50, pointer;
}

.merchant-cursor:hover {
    cursor: url("/images/cursors/merchant-1x.png") 15 40, pointer;
    cursor: image-set( "/images/cursors/merchant-1x.png" 1x, "/images/cursors/merchant-2x.png" 2x) 15 50, pointer;
}

.house-cursor {
    &.orange:hover {
        cursor: url("/images/cursors/orange-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/orange-house.png" 1x, "/images/cursors/orange-house.png" 2x) 15 50, pointer;
    }
    &.white:hover {
        cursor: url("/images/cursors/white-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/white-house.png" 1x, "/images/cursors/white-house.png" 2x) 15 50, pointer;
    }
    &.red:hover {
        cursor: url("/images/cursors/red-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/red-house.png" 1x, "/images/cursors/red-house.png" 2x) 15 50, pointer;
    }
    &.blue:hover {
        cursor: url("/images/cursors/blue-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/blue-house.png" 1x, "/images/cursors/blue-house.png" 2x) 15 50, pointer;
    }
    &.green:hover {
        cursor: url("/images/cursors/green-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/green-house.png" 1x, "/images/cursors/green-house.png" 2x) 15 50, pointer;
    }
    &.brown:hover {
        cursor: url("/images/cursors/brown-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/brown-house.png" 1x, "/images/cursors/brown-house.png" 2x) 15 50, pointer;
    }
    &.pink:hover {
        cursor: url("/images/cursors/pink-house.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/pink-house.png" 1x, "/images/cursors/pink-house.png" 2x) 15 50, pointer;
    }
}
.city-cursor {
    &.orange:hover {
        cursor: url("/images/cursors/orange-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/orange-city.png" 1x, "/images/cursors/orange-city.png" 2x) 15 50, pointer;
    }
    &.white:hover {
        cursor: url("/images/cursors/white-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/white-city.png" 1x, "/images/cursors/white-city.png" 2x) 15 50, pointer;
    }
    &.red:hover {
        cursor: url("/images/cursors/red-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/red-city.png" 1x, "/images/cursors/red-city.png" 2x) 15 50, pointer;
    }
    &.blue:hover {
        cursor: url("/images/cursors/blue-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/blue-city.png" 1x, "/images/cursors/blue-city.png" 2x) 15 50, pointer;
    }
    &.green:hover {
        cursor: url("/images/cursors/green-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/green-city.png" 1x, "/images/cursors/green-city.png" 2x) 15 50, pointer;
    }
    &.brown:hover {
        cursor: url("/images/cursors/brown-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/brown-city.png" 1x, "/images/cursors/brown-city.png" 2x) 15 50, pointer;
    }
    &.pink:hover {
        cursor: url("/images/cursors/pink-city.png") 15 40, pointer;
        cursor: image-set( "/images/cursors/pink-city.png" 1x, "/images/cursors/pink-city.png" 2x) 15 50, pointer;
    }
}

.trade-wrapper {
    width: 810px;
    user-select: none;
}

.quick-trade-wrapper {
    position: fixed;
    bottom: 216px;
    right: 33px;
    z-index: 99;
}

.get-trade-selected-wrapper,
.give-trade-selected-wrapper {
    height: 56px;
    width: 300px;
    display: block;
    position: relative;
    left: 16px;
}

.get-trade-selected-wrapper .icon-wrapper,
.give-trade-selected-wrapper .icon-wrapper {
    position: absolute;
    left: -44px;
    top: 10px;
    color: $textCoin;
    font-size: 12px;
}

.get-trade-selected-wrapper .icon-wrapper-right,
.give-trade-selected-wrapper .icon-wrapper-right {
    left: 318px;
}

.get-trade-selected-wrapper .player-trade-color {
    position: absolute;
    width: 4px;
    height: 98px;
    top: 0px;
    left: -14px;
}

.get-trade-selected-wrapper .player-trade-color-right {
    left: 306px;
}

.get-trade-wrapper,
.give-trade-wrapper {
    float: left;
    width: 300px;
    height: 50px;
}

.quick-trade-cards {
    text-align: right;
    display: none;
    cursor: default;
    padding-top: 14px;
    padding-bottom: 8px;
    padding-left: 10px;
}
.get-trade-cards,
.give-trade-cards {
    display: none;
    cursor: default;
}

.quick-trade-cards .hand-card-small {
    transition: all .3s ease;
}

.get-trade-cards .hand-card-small,
.give-trade-cards .hand-card-small {
    transition: all .1s linear;
    position: relative;
    bottom: 0px;
}

.quick-trade-cards .hand-card-small:hover {
    margin-bottom: 2px;
}

.get-trade-cards .hand-card-small:hover,
.give-trade-cards .hand-card-small:hover {
    bottom: 2px;
}

.quick-trade-trigger {
    display: block;
    width: 140px;
    float: right;
    text-align: center;
    margin-top: -42px;
    margin-right: 4px;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid #535249;
    color: $textCoin;
    border-radius: 12px;
    padding: 2px 12px;
    cursor: default;
    background: $gray;
    user-select: none;
}

.get-trade-trigger,
.give-trade-trigger {
    display: block;
    width: 104px;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
    border: 1px solid $textCoin;
    color: $textCoin;
    border-radius: 12px;
    padding: 2px 12px;
    cursor: default;
    background: $gray;
}

.quick-trade-wrapper:hover .quick-trade-cards,
.get-trade-wrapper:hover .get-trade-cards,
.give-trade-wrapper:hover .give-trade-cards {
    display: block;
    user-select: none;
}

.quick-trade-wrapper:hover .quick-trade-trigger,
.get-trade-wrapper:hover .get-trade-trigger,
.give-trade-wrapper:hover .give-trade-trigger {
    display: none;
}

.float-right {
    float: right;
}

.cities-knights-count-mobile {
    display: flex;
    position: absolute;
    top: 40px;
    justify-content: space-between;
    width: 100%;
    left: 0px;
    user-select: none;
    padding: 20px;
    color: $textCoin;
    font-size: 16px;
    letter-spacing: 2px;
    font-family: 'Montserrat', sans-serif;
}

.cities-knights-count {
    display: flex;
    justify-content: center;
    position: absolute;
    top: -70px;
    width: 150px;
    left: 40px;
    user-select: none;
}

.cities-knights-count > div {
    width: 34px;
    height: 30px;
    text-align: center;
    font-size: 14px;
    position: relative;
    text-align: left;
    line-height: 30px;
}

.main-player {
    z-index: 12;
}

.opponent-trade-wrapper {
    padding-top: 16px;
    padding-left: 36px;
    margin-left: -36px;
    padding-bottom: 0px;
    transition: all .3s ease;
}

.opponent-trade-wrapper:hover {
    background: $lightGray;
}

.bank-trade-row {
    padding-top: 12px;
    clear: both;
}

.bank-trade-row.commodity-margin {
    margin-left: 3px;
}

.bank-trade-wrapper {
    padding-bottom: 28px;
    margin-left: -6px;
    padding-left: 6px;
    margin-top: 16px;
    transition: all .3s ease;
}

.bank-trade-wrapper:hover {
    background: $lightGray;
}

.bank-item {
    position: relative !important;
    margin: 10px auto;
    left: 0;
    top: 0;
}

.background {
    &.orange { background-color: $orange; }
    &.white { background-color: $white; }
    &.red { background-color: $red; }
    &.blue { background-color: $blue; }
    &.green { background-color: $green; }
    &.brown { background-color: $brown; }
    &.pink { background-color: $pink; }
}

.text {
    &.orange { color: $orange; }
    &.white { color: $white; }
    &.red { color: $red; }
    &.blue { color: $blue; }
    &.green { color: $green; }
    &.brown { color: $brown; }
    &.pink { color: $pink; }

    &.cloth { color: $textCloth; }
    &.coin { color: $textCoin; }
    &.paper { color: $textPaper; }
}

.empty-highlighted-spot {
    width: 12px;
    height: 12px;
    background:rgba(60,60,60, .85);
    border-radius: 50%;
    position: absolute;
    left: 10px;
    top: 12px;
    animation: emptyHighlightedSpotAnimation 2.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .15s ease;
}

@keyframes emptyHighlightedSpotAnimation {
  0% { transform: scale(0.7); }
  25% { transform: scale(1.3); }
  50% { transform: scale(0.7);  }
  75% { transform: scale(1.3); }
  100% { transform: scale(0.7);  }
}



/* houses */
.house {
    position: absolute;
    width: 26px;
    height: 26px;
    left: 3px;
    top: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;

    &.orange { background-image: url('/images/pieces/orange-house.png'); }
    &.white { background-image: url('/images/pieces/white-house.png'); }
    &.red { background-image: url('/images/pieces/red-house.png'); }
    &.blue { background-image: url('/images/pieces/blue-house.png'); }
    &.green { background-image: url('/images/pieces/green-house.png'); }
    &.brown { background-image: url('/images/pieces/brown-house.png'); }
    &.pink { background-image: url('/images/pieces/pink-house.png'); }
    .island-marker {
        background-image: url('/images/pieces/island-marker.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 26px;
        height: 26px;
    }
}
.house.animate {
    animation: houseAnimation .7s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

@keyframes houseAnimation {
    0% { top: -26px; }
    100% { top: 2px; }
}

.city.animate {
    animation: cityAnimation .8s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes cityAnimation {
    0% { transform: scale(.92) }
    10% { transform: scale(.92) }
    100% { transform: scale(1) }
}

.knight.animate {
    animation: knightAnimation .5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes knightAnimation {
    0% { transform: scale(2); }
    10% { transform: scale(2); }
    100% { transform: scale(1); }
}

.knight.active.animate {
    animation: activateKnightAnimation .2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

.citywall-only {
    position: absolute;
    width: 34px;
    height: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    // &.orange { background-image: url('/images/pieces/orange-citywall.png'); }
    // &.white { background-image: url('/images/pieces/white-citywall.png'); }
    // &.red { background-image: url('/images/pieces/red-citywall.png'); }
    // &.blue { background-image: url('/images/pieces/blue-citywall.png'); }
    // &.green { background-image: url('/images/pieces/green-citywall.png'); }
    // &.brown { background-image: url('/images/pieces/brown-citywall.png'); }
    // &.pink { background-image: url('/images/pieces/pink-citywall.png'); }
}

/* cities */
.city {
    position: absolute;
    width: 34px;
    height: 34px;
    left: -2px;
    top: -3px;
    // left: -10px;
    // top: -15px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 3;

    // &.neutral { background-image: url('/images/pieces/neutral-city.png'); }

    &.orange { background-image: url('/images/pieces/orange-city.png'); }
    &.orange.citywall { background-image: url('/images/pieces/orange-city-citywall.png'); }
    &.orange.citywall.city_disabled { background-image: url('/images/pieces/orange-city-citywall-disabled.png'); }
    &.orange.metropolis { background-image: url('/images/pieces/orange-city-metropolis.png'); }
    &.orange.citywall.metropolis { background-image: url('/images/pieces/orange-city-citywall-metropolis.png'); }

    &.white { background-image: url('/images/pieces/white-city.png'); }
    &.white.citywall { background-image: url('/images/pieces/white-city-citywall.png'); }
    &.white.citywall.city_disabled { background-image: url('/images/pieces/white-city-citywall-disabled.png'); }
    &.white.metropolis { background-image: url('/images/pieces/white-city-metropolis.png'); }
    &.white.citywall.metropolis { background-image: url('/images/pieces/white-city-citywall-metropolis.png'); }

    &.red { background-image: url('/images/pieces/red-city.png'); }
    &.red.citywall { background-image: url('/images/pieces/red-city-citywall.png'); }
    &.red.citywall.city_disabled { background-image: url('/images/pieces/red-city-citywall-disabled.png'); }
    &.red.metropolis { background-image: url('/images/pieces/red-city-metropolis.png'); }
    &.red.citywall.metropolis { background-image: url('/images/pieces/red-city-citywall-metropolis.png'); }

    &.blue { background-image: url('/images/pieces/blue-city.png'); }
    &.blue.citywall { background-image: url('/images/pieces/blue-city-citywall.png'); }
    &.blue.citywall.city_disabled { background-image: url('/images/pieces/blue-city-citywall-disabled.png'); }
    &.blue.metropolis { background-image: url('/images/pieces/blue-city-metropolis.png'); }
    &.blue.citywall.metropolis { background-image: url('/images/pieces/blue-city-citywall-metropolis.png'); }

    &.green { background-image: url('/images/pieces/green-city.png'); }
    &.green.citywall { background-image: url('/images/pieces/green-city-citywall.png'); }
    &.green.citywall.city_disabled { background-image: url('/images/pieces/green-city-citywall-disabled.png'); }
    &.green.metropolis { background-image: url('/images/pieces/green-city-metropolis.png'); }
    &.green.citywall.metropolis { background-image: url('/images/pieces/green-city-citywall-metropolis.png'); }

    &.brown { background-image: url('/images/pieces/brown-city.png'); }
    &.brown.citywall { background-image: url('/images/pieces/brown-city-citywall.png'); }
    &.brown.citywall.city_disabled { background-image: url('/images/pieces/brown-city-citywall-disabled.png'); }
    &.brown.metropolis { background-image: url('/images/pieces/brown-city-metropolis.png'); }
    &.brown.citywall.metropolis { background-image: url('/images/pieces/brown-city-citywall-metropolis.png'); }

    &.pink { background-image: url('/images/pieces/pink-city.png'); }
    &.pink.citywall { background-image: url('/images/pieces/pink-city-citywall.png'); }
    &.pink.citywall.city_disabled { background-image: url('/images/pieces/pink-city-citywall-disabled.png'); }
    &.pink.metropolis { background-image: url('/images/pieces/pink-city-metropolis.png'); }
    &.pink.citywall.metropolis { background-image: url('/images/pieces/pink-city-citywall-metropolis.png'); }

    &.city_disabled:not(.citywall) {
        transform: rotate(120deg);
        // top: 3px;
        top: -3px;
        left: 0px;
    }

    &.citywall.city_disabled {
        width: 50px;
        height: 50px;
        top: -3px;
        left: -10px;
    }

    &.citywall {
        width: 50px;
        height: 50px;
        top: -9px;
        left: -9px;
    }

    &.citywall.metropolis {
        width: 55px;
        height: 55px;
        top: -18px;
        left: -9px;
    }
    &.metropolis {
        width: 50px;
        height: 50px;
        top: -12px;
        left: -6px;
    }
}

.city-on-map {
    left: -2px;
    top: -3px;
}

.highlighted-cards {
    animation: highlightCardAnimation 2.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

@keyframes highlightCardAnimation {
  0% { transform: scale(1.0); }
  25% { transform: scale(1.1); }
  50% { transform: scale(1.0); }
  75% { transform: scale(1.1); }
  100% { transform: scale(1.0); }
}

.highlighted {
    animation: highlightAnimation 2.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

.highlighted-small {
    animation: highlightAnimationSmall 2.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}

.highlighted.city_disabled:not(.citywall) {
    animation: highlightAnimationDisabled 2.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
}
.build-house-animation {
    position: absolute;
    animation: buildHouseAnimation 0.6s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

@keyframes buildHouseAnimation {
    0% { transform: scale(1.8); top: -25px; }
    100% { transform: scale(1.0); top: 2px; }
}

.build-road-animation {
    animation: buildRoadAnimation 1s;
    animation-iteration-count: 1;
    // animation-timing-function: ease-out;
}

@keyframes buildRoadAnimation {
    0% { transform: rotate(90deg); opacity: 0; }
    60% { transform: rotate(90deg); opacity: 1; }
    100% { transform: rotate(90deg); opacity: 1; }
}

.build-boat-animation {
    animation: buildBoatAnimation 1s;
    animation-iteration-count: 1;
    // animation-timing-function: ease-out;
}

@keyframes buildBoatAnimation {
    0% { transform: rotate(90deg); opacity: 0; }
    60% { transform: rotate(90deg); opacity: 1; }
    100% { transform: rotate(90deg); opacity: 1; }
}

.build-city-animation {
    position: absolute;
    animation: buildCityAnimation 0.6s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
}

.points-animation {
    user-select: none;
    position: absolute;
    top: 2px;
    left: 6px;
    animation: pointsAnimation 1.2s;
    animation-iteration-count: 1;
    // animation-iteration-count: infinite;
    animation-timing-function: ease;
    color: $white;
    font-weight: bold;
    text-align: center;
    opacity: 1;
    width: 20px;
}

@keyframes pointsAnimation {
    0% { top: -0px; }
    50% { opacity: 1; }
    80% { top: -35px; }
    90% { opacity: 0; }
    100% { top: -45px; opacity: 0; }
}

@keyframes buildCityAnimation {
    0% { transform: scale(1.8); top: -37px; left: -2px; }
    100% { transform: scale(1.0); top: -3px; left: -2px; }
}

@keyframes highlightAnimation {
  0% { transform: scale(1.0); }
  25% { transform: scale(1.3); }
  50% { transform: scale(1.0); }
  75% { transform: scale(1.3); }
  100% { transform: scale(1.0); }
}

@keyframes highlightAnimationSmall {
    0% { transform: scale(0.9); }
    25% { transform: scale(1.1); }
    50% { transform: scale(0.9); }
    75% { transform: scale(1.1); }
    100% { transform: scale(0.9); }
  }

@keyframes highlightAnimationDisabled {
  0% { transform: scale(1.2) rotate(120deg); }
  25% { transform: scale(1.4) rotate(120deg); }
  50% { transform: scale(1.2) rotate(120deg); }
  75% { transform: scale(1.4) rotate(120deg); }
  100% { transform: scale(1.2) rotate(120deg); }
}

/* knights */
.knight {
    position: absolute;
    // width: 24px;
    // height: 24px;
    left: 5px;
    top: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 99;

    &.neutral.active.level-1 { background-image: url('/images/pieces/neutral-knight-1-active.png'); }

    &.orange.level-1 { background-image: url('/images/pieces/orange-knight-1.png'); }
    &.orange.active.level-1 { background-image: url('/images/pieces/orange-knight-1-active.png'); }
    &.orange.level-2 { background-image: url('/images/pieces/orange-knight-2.png'); }
    &.orange.active.level-2  { background-image: url('/images/pieces/orange-knight-2-active.png'); }
    &.orange.level-3 { background-image: url('/images/pieces/orange-knight-3.png'); }
    &.orange.active.level-3  { background-image: url('/images/pieces/orange-knight-3-active.png'); }

    &.white.level-1 { background-image: url('/images/pieces/white-knight-1.png'); }
    &.white.active.level-1 { background-image: url('/images/pieces/white-knight-1-active.png'); }
    &.white.level-2 { background-image: url('/images/pieces/white-knight-2.png'); }
    &.white.active.level-2  { background-image: url('/images/pieces/white-knight-2-active.png'); }
    &.white.level-3 { background-image: url('/images/pieces/white-knight-3.png'); }
    &.white.active.level-3  { background-image: url('/images/pieces/white-knight-3-active.png'); }

    &.red.level-1 { background-image: url('/images/pieces/red-knight-1.png'); }
    &.red.active.level-1 { background-image: url('/images/pieces/red-knight-1-active.png'); }
    &.red.level-2 { background-image: url('/images/pieces/red-knight-2.png'); }
    &.red.active.level-2  { background-image: url('/images/pieces/red-knight-2-active.png'); }
    &.red.level-3 { background-image: url('/images/pieces/red-knight-3.png'); }
    &.red.active.level-3  { background-image: url('/images/pieces/red-knight-3-active.png'); }

    &.blue.level-1 { background-image: url('/images/pieces/blue-knight-1.png'); }
    &.blue.active.level-1 { background-image: url('/images/pieces/blue-knight-1-active.png'); }
    &.blue.level-2 { background-image: url('/images/pieces/blue-knight-2.png'); }
    &.blue.active.level-2  { background-image: url('/images/pieces/blue-knight-2-active.png'); }
    &.blue.level-3 { background-image: url('/images/pieces/blue-knight-3.png'); }
    &.blue.active.level-3  { background-image: url('/images/pieces/blue-knight-3-active.png'); }

    &.green.level-1 { background-image: url('/images/pieces/green-knight-1.png'); }
    &.green.active.level-1 { background-image: url('/images/pieces/green-knight-1-active.png'); }
    &.green.level-2 { background-image: url('/images/pieces/green-knight-2.png'); }
    &.green.active.level-2  { background-image: url('/images/pieces/green-knight-2-active.png'); }
    &.green.level-3 { background-image: url('/images/pieces/green-knight-3.png'); }
    &.green.active.level-3  { background-image: url('/images/pieces/green-knight-3-active.png'); }

    &.brown.level-1 { background-image: url('/images/pieces/brown-knight-1.png'); }
    &.brown.active.level-1 { background-image: url('/images/pieces/brown-knight-1-active.png'); }
    &.brown.level-2 { background-image: url('/images/pieces/brown-knight-2.png'); }
    &.brown.active.level-2  { background-image: url('/images/pieces/brown-knight-2-active.png'); }
    &.brown.level-3 { background-image: url('/images/pieces/brown-knight-3.png'); }
    &.brown.active.level-3  { background-image: url('/images/pieces/brown-knight-3-active.png'); }

    &.pink.level-1 { background-image: url('/images/pieces/pink-knight-1.png'); }
    &.pink.active.level-1 { background-image: url('/images/pieces/pink-knight-1-active.png'); }
    &.pink.level-2 { background-image: url('/images/pieces/pink-knight-2.png'); }
    &.pink.active.level-2  { background-image: url('/images/pieces/pink-knight-2-active.png'); }
    &.pink.level-3 { background-image: url('/images/pieces/pink-knight-3.png'); }
    &.pink.active.level-3  { background-image: url('/images/pieces/pink-knight-3-active.png'); }
}


.knight-3d {
    position: absolute;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;

    &.neutral.active.level-1 { background-image: url('/images/pieces/neutral-knight-1-active-3d.png'); }
    &.neutral.level-1 { background-image: url('/images/pieces/neutral-knight-1-3d.png'); }
    &.neutral.active.level-2 { background-image: url('/images/pieces/neutral-knight-2-active-3d.png'); }
    &.neutral.level-2 { background-image: url('/images/pieces/neutral-knight-2-3d.png'); }
    &.neutral.active.level-3 { background-image: url('/images/pieces/neutral-knight-3-active-3d.png'); }
    &.neutral.level-3 { background-image: url('/images/pieces/neutral-knight-3-3d.png'); }

    &.orange.level-1 { background-image: url('/images/pieces/orange-knight-1-3d.png'); }
    &.orange.active.level-1 { background-image: url('/images/pieces/orange-knight-1-active-3d.png'); }
    &.orange.level-2 { background-image: url('/images/pieces/orange-knight-2-3d.png'); }
    &.orange.active.level-2  { background-image: url('/images/pieces/orange-knight-2-active-3d.png'); }
    &.orange.level-3 { background-image: url('/images/pieces/orange-knight-3-3d.png'); }
    &.orange.active.level-3  { background-image: url('/images/pieces/orange-knight-3-active-3d.png'); }

    &.white.level-1 { background-image: url('/images/pieces/white-knight-1-3d.png'); }
    &.white.active.level-1 { background-image: url('/images/pieces/white-knight-1-active-3d.png'); }
    &.white.level-2 { background-image: url('/images/pieces/white-knight-2-3d.png'); }
    &.white.active.level-2  { background-image: url('/images/pieces/white-knight-2-active-3d.png'); }
    &.white.level-3 { background-image: url('/images/pieces/white-knight-3-3d.png'); }
    &.white.active.level-3  { background-image: url('/images/pieces/white-knight-3-active-3d.png'); }

    &.red.level-1 { background-image: url('/images/pieces/red-knight-1-3d.png'); }
    &.red.active.level-1 { background-image: url('/images/pieces/red-knight-1-active-3d.png'); }
    &.red.level-2 { background-image: url('/images/pieces/red-knight-2-3d.png'); }
    &.red.active.level-2  { background-image: url('/images/pieces/red-knight-2-active-3d.png'); }
    &.red.level-3 { background-image: url('/images/pieces/red-knight-3-3d.png'); }
    &.red.active.level-3  { background-image: url('/images/pieces/red-knight-3-active-3d.png'); }

    &.blue.level-1 { background-image: url('/images/pieces/blue-knight-1-3d.png'); }
    &.blue.active.level-1 { background-image: url('/images/pieces/blue-knight-1-active-3d.png'); }
    &.blue.level-2 { background-image: url('/images/pieces/blue-knight-2-3d.png'); }
    &.blue.active.level-2  { background-image: url('/images/pieces/blue-knight-2-active-3d.png'); }
    &.blue.level-3 { background-image: url('/images/pieces/blue-knight-3-3d.png'); }
    &.blue.active.level-3  { background-image: url('/images/pieces/blue-knight-3-active-3d.png'); }

    &.green.level-1 { background-image: url('/images/pieces/green-knight-1-3d.png'); }
    &.green.active.level-1 { background-image: url('/images/pieces/green-knight-1-active-3d.png'); }
    &.green.level-2 { background-image: url('/images/pieces/green-knight-2-3d.png'); }
    &.green.active.level-2  { background-image: url('/images/pieces/green-knight-2-active-3d.png'); }
    &.green.level-3 { background-image: url('/images/pieces/green-knight-3-3d.png'); }
    &.green.active.level-3  { background-image: url('/images/pieces/green-knight-3-active-3d.png'); }

    &.brown.level-1 { background-image: url('/images/pieces/brown-knight-1-3d.png'); }
    &.brown.active.level-1 { background-image: url('/images/pieces/brown-knight-1-active-3d.png'); }
    &.brown.level-2 { background-image: url('/images/pieces/brown-knight-2-3d.png'); }
    &.brown.active.level-2  { background-image: url('/images/pieces/brown-knight-2-active-3d.png'); }
    &.brown.level-3 { background-image: url('/images/pieces/brown-knight-3-3d.png'); }
    &.brown.active.level-3  { background-image: url('/images/pieces/brown-knight-3-active-3d.png'); }

    &.pink.level-1 { background-image: url('/images/pieces/pink-knight-1-3d.png'); }
    &.pink.active.level-1 { background-image: url('/images/pieces/pink-knight-1-active-3d.png'); }
    &.pink.level-2 { background-image: url('/images/pieces/pink-knight-2-3d.png'); }
    &.pink.active.level-2  { background-image: url('/images/pieces/pink-knight-2-active-3d.png'); }
    &.pink.level-3 { background-image: url('/images/pieces/pink-knight-3-3d.png'); }
    &.pink.active.level-3  { background-image: url('/images/pieces/pink-knight-3-active-3d.png'); }
}

.citywall.bank-item {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 4px;
    top: 4px;
    background-size: contain;
    background-repeat: no-repeat;
}

.citywall {
    &.orange { background-image: url('/images/pieces/orange-citywall.png'); }
    &.white { background-image: url('/images/pieces/white-citywall.png'); }
    &.red { background-image: url('/images/pieces/red-citywall.png'); }
    &.blue { background-image: url('/images/pieces/blue-citywall.png'); }
    &.green { background-image: url('/images/pieces/green-citywall.png'); }
    &.brown { background-image: url('/images/pieces/brown-citywall.png'); }
    &.pink { background-image: url('/images/pieces/pink-citywall.png'); }
}

.piece.merchant {
    width: 23px;
    height: 35px;
    background-image: url('/images/pieces/merchant.svg');
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    &.position-0 {
        z-index: -1;
        left: 5px;
        top: -38px;
    }
    &.position-1 {
        z-index: -1;
        top: -21px;
        left: 29px;
    }
    &.position-2 {
        z-index: 4;
        top: 5px;
        left: 28px;
    }
    &.position-3 {
        z-index: 4;
        left: 5px;
        top: 17px;
    }
    &.position-4 {
        z-index: 4;
        top: 5px;
        left: -20px;
    }
    &.position-5 {
        z-index: -1;
        top: -21px;
        left: -20px;
    }
}

.road-spot-wrapper {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -1px;
    top: -4px;
}

.road {
    position: absolute;
    width: 10px;
    height: 40px;
    left: 10px;
    top: -12px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(90deg);

    &.orange { background-image: url('/images/pieces/orange-road.png'); }
    &.white { background-image: url('/images/pieces/white-road.png'); }
    &.red { background-image: url('/images/pieces/red-road.png'); }
    &.blue { background-image: url('/images/pieces/blue-road.png'); }
    &.green { background-image: url('/images/pieces/green-road.png'); }
    &.brown { background-image: url('/images/pieces/brown-road.png'); }
    &.pink { background-image: url('/images/pieces/pink-road.png'); }

    &.longest {
        position: absolute;
        width: 16px;
        height: 46px;
        left: 7px;
        top: -9px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transform: rotate(90deg);

        &.orange { background-image: url('/images/pieces/orange-road-longest.png'); }
        &.white { background-image: url('/images/pieces/white-road-longest.png'); }
        &.red { background-image: url('/images/pieces/red-road-longest.png'); }
        &.blue { background-image: url('/images/pieces/blue-road-longest.png'); }
        &.green { background-image: url('/images/pieces/green-road-longest.png'); }
        &.brown { background-image: url('/images/pieces/brown-road-longest.png'); }
        &.pink { background-image: url('/images/pieces/pink-road-longest.png'); }
    }
}

.boat {
    position: absolute;
    width: 16px;
    height: 40px;
    left: 6px;
    top: -9px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(90deg);

    &.orange { background-image: url('/images/pieces/orange-boat.png'); }
    &.white { background-image: url('/images/pieces/white-boat.png'); }
    &.red { background-image: url('/images/pieces/red-boat.png'); }
    &.blue { background-image: url('/images/pieces/blue-boat.png'); }
    &.green { background-image: url('/images/pieces/green-boat.png'); }
    &.brown { background-image: url('/images/pieces/brown-boat.png'); }
    &.pink { background-image: url('/images/pieces/pink-boat.png'); }

    &.longest {
        position: absolute;
        width: 22px;
        height: 46px;
        left: 5px;
        top: -9px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transform: rotate(90deg);

        &.orange { background-image: url('/images/pieces/orange-boat-longest.png'); }
        &.white { background-image: url('/images/pieces/white-boat-longest.png'); }
        &.red { background-image: url('/images/pieces/red-boat-longest.png'); }
        &.blue { background-image: url('/images/pieces/blue-boat-longest.png'); }
        &.green { background-image: url('/images/pieces/green-boat-longest.png'); }
        &.brown { background-image: url('/images/pieces/brown-boat-longest.png'); }
        &.pink { background-image: url('/images/pieces/pink-boat-longest.png'); }
    }
}

.highlighted-road-spot {
    animation: highlightRoadAnimation .8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}


@keyframes highlightRoadAnimation {
    0% { transform: scaleX(1.05) scaleY(1.0) rotate(87deg); }
    50% { transform: scaleX(1.05) scaleY(1.0) rotate(93deg); }
    100% { transform: scaleX(1.05) scaleY(1.0) rotate(87deg); }
}


.highlighted-boat-spot {
    // animation: highlightBoatAnimation 2.2s;
    animation: highlightBoatAnimation .8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes highlightBoatAnimation {
//   0% { transform: scaleX(1.0) scaleY(0.9) rotate(90deg); }
//   25% { transform: scaleX(1.2) scaleY(1.3) rotate(90deg); }
//   50% { transform: scaleX(1.0) scaleY(0.9) rotate(90deg); }
//   75% { transform: scaleX(1.2) scaleY(1.3) rotate(90deg); }
//   100% { transform: scaleX(1.0) scaleY(0.9) rotate(90deg); }
  0% { transform: scaleX(1.1) scaleY(1.0) rotate(86deg); }
  50% { transform: scaleX(1.1) scaleY(1.0) rotate(94deg); }
  100% { transform: scaleX(1.1) scaleY(1.0) rotate(86deg); }
}

.road-3d {
    position: absolute;
    width: 44px;
    height: 22px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    // &.orange { background-image: url('/images/pieces/orange-road-3d.png'); }
    // &.white { background-image: url('/images/pieces/white-road-3d.png'); }
    // &.red { background-image: url('/images/pieces/red-road-3d.png'); }
    // &.blue { background-image: url('/images/pieces/blue-road-3d.png'); }
    // &.green { background-image: url('/images/pieces/green-road-3d.png'); }
    // &.brown { background-image: url('/images/pieces/brown-road-3d.png'); }
    // &.pink { background-image: url('/images/pieces/pink-road-3d.png'); }
}

.boat-3d {
    position: absolute;
    width: 44px;
    height: 22px;
    left: 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    // &.orange { background-image: url('/images/pieces/orange-boat-3d.png'); }
    // &.white { background-image: url('/images/pieces/white-boat-3d.png'); }
    // &.red { background-image: url('/images/pieces/red-boat-3d.png'); }
    // &.blue { background-image: url('/images/pieces/blue-boat-3d.png'); }
    // &.green { background-image: url('/images/pieces/green-boat-3d.png'); }
    // &.brown { background-image: url('/images/pieces/brown-boat-3d.png'); }
    // &.pink { background-image: url('/images/pieces/pink-boat-3d.png'); }
}

.road-menu-item {
    top: 3px;
    left: -7px;
}

.boat-menu-item {
    top: 3px;
    left: -3px;
}


.roads {
    position: absolute;
    width: 63px;
    height: 30px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    // &.orange { background-image: url('/images/pieces/orange-roads.png'); }
    // &.white { background-image: url('/images/pieces/white-roads.png'); }
    // &.red { background-image: url('/images/pieces/red-roads.png'); }
    // &.blue { background-image: url('/images/pieces/blue-roads.png'); }
    // &.green { background-image: url('/images/pieces/green-roads.png'); }
    // &.brown { background-image: url('/images/pieces/brown-roads.png'); }
    // &.pink { background-image: url('/images/pieces/pink-roads.png'); }
}

.mono {
    font-family: 'IBM Plex Mono', monospace !important;
}

a {
    color: $textCoin;

    &:hover { color: $desert; }
}

.chats {
    margin-top: 12px;
    border-top: 2px solid $textCoin;
    background-color: $gray;
    // position: fixed;
    width: 100%;
    z-index: 20;
    padding: 6px;
    user-select: none;
    // font-family: 'IBM Plex Mono', monospace !important;
    font-size: 14px;
    color: $white;
    max-height: 220px;
    overflow-y: scroll;

    .chat {
        display: flex;
    }
}

.statuses {
    // display: none;
    background-color: $gray;
    position: fixed;
    width: 520px;
    left: 55%;
    top: 20px;
    transform: translate(-57.5%, 0);
    z-index: 20;
    padding: 6px;
    // padding-bottom: 10px;
    user-select: none;
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 12px;
    color: $textCoin;

    .caret {
        right: 10px;
        top: 10px;
    }

    .caret-unflat {
        right: 14px;
        top: 14px;
    }

    // .symbol:first-of-type { margin-right: 4px; }

    .symbol {
        position: relative;
        left: 0px;
        top: 1px;

        &.lumber,
        &.brick,
        &.wool,
        &.grain,
        &.ore {
            left: -2px;
            top: 3px;
        }

        &.basic-progress,
        &.cloth-progress,
        &.coin-progress,
        &.paper-progress {
            margin-right: 10px;
        }
    }

    .status {
        padding-left: 2px;
    }

    .status-row {
        margin: 4px;

        clear: both;
        min-height: 20px;

        div {
            float: left;
            margin-right: 8px;
        }

        .symbol {
            &.war, &.metropolis {
                margin-right: 5px;
            }
        }
    }
}

.hand-cards-numbers {
    user-select: none;
    position: absolute;
    font-size: 9px;
    color: $textCoin;
    right: -14px;
    bottom: 1px;
    text-align: left;
    font-family: 'IBM Plex Mono', monospace;

    .current {
        border-bottom: 1px solid $textCoin;
        text-align: center;
    }

    .total {
        &.safe {
            color: $cloth;
        }
    }
}


.throw-away-cards-text {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    text-align: right;
    margin-right: 60px;
    text-transform: uppercase;
    float: left;
}

.resource-commodity-gap {
    margin-right: 52px;
    height: 93px;
    float: right;
}

.hand-card-small {
    display: inline-block;
    width: 30px;
    height: 42px;
    margin: 0px 1px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 2px;
    margin-right: 5px;
    border: 1px solid $gray;
}

.hand-card-xs {
    display: inline-block;
    width: 21px;
    height: 30px;
    margin: 0px 1px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 1px;
    margin-right: 3px;
    margin-bottom: 3px;
    border: 1px solid $gray;
}

.incoming-animation {
    animation: incoming 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

.outgoing-animation {
    animation: outgoing 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    position: relative;
}

.opponent-hand-animation {
    animation: opponentSlideIn 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

.hand-card.slide-in {
    animation: slideIn 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

.hand-card.slide-out {
    animation: slideOut .7s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
}

.hand-card.selected {
    margin-top: -16px;
}

@keyframes slideIn {
    0% { margin-top: -16px; }
    50% { margin-top: -16px; }
    100% { margin-top: 0px; }
}

@keyframes slideOut {
    0% { margin-top: 0px; }
    50% { margin-top: -16px; }
    100% { margin-top: -16px; }
}

@keyframes incoming {
    0% { transform: scaleX(.3) rotate(45deg) }
    10% { transform: scaleX(1) rotate(0deg) }
    80% { transform: scaleX(1) rotate(0deg); opacity: 1; }
    100% { transform: scaleX(1) rotate(0deg); opacity: 0; }
}

@keyframes outgoing {
    0% { transform: scaleX(.3) rotate(45deg); bottom: 0px; }
    10% { transform: scaleX(1) rotate(0deg) }
    40% { transform: scaleX(1) rotate(0deg); opacity: 1; }
    100% { transform: scaleX(1) rotate(0deg); opacity: 0; bottom: 10px; }
}

@keyframes opponentSlideIn {
    0% { transform: scaleX(.5) }
    15% { transform: scaleX(1) }
    100% { transform: scaleX(1) }
}

.back { background-image: url('/images/cards/back.png'); }

// .hand-card, .card-small {
.card-small {
    &.lumber { background-image: url('/images/cards/lumber.png'); background-color: $lumber; }
    &.brick { background-image: url('/images/cards/brick.png'); background-color: $brick; }
    &.wool { background-image: url('/images/cards/wool.png'); background-color: $wool; }
    &.grain { background-image: url('/images/cards/grain.png'); background-color: $grain; }
    &.ore { background-image: url('/images/cards/ore.png'); background-color: $ore; }
    &.cloth { background-image: url('/images/cards/cloth.png'); background-color: $cloth; }
    &.coin { background-image: url('/images/cards/coin.png'); background-color: $coin; }
    &.paper { background-image: url('/images/cards/paper.png'); background-color: $paper; }
    &.back { background-image: url('/images/cards/back.png'); background-color: $water; }
}

.hand-card-2, .card-small {
    &.lumber-2 { background-image: url('/images/cards/lumber-2.png'); background-color: $lumber; }
    &.brick-2 { background-image: url('/images/cards/brick-2.png'); background-color: $brick; }
    &.wool-2 { background-image: url('/images/cards/wool-2.png'); background-color: $wool; }
    &.grain-2 { background-image: url('/images/cards/grain-2.png'); background-color: $grain; }
    &.ore-2 { background-image: url('/images/cards/ore-2.png'); background-color: $ore; }
    &.cloth-2 { background-image: url('/images/cards/cloth-2.png'); background-color: $cloth; }
    &.coin-2 { background-image: url('/images/cards/coin-2.png'); background-color: $coin; }
    &.paper-2 { background-image: url('/images/cards/paper-2.png'); background-color: $paper; }
    &.back-2 { background-image: url('/images/cards/back-2.png'); background-color: $water; }
}


.hand-card-small, .hand-card-xs {
    &.lumber { background-image: url('/images/cards/lumber-small.png'); }
    &.brick { background-image: url('/images/cards/brick-small.png'); }
    &.wool { background-image: url('/images/cards/wool-small.png'); }
    &.grain { background-image: url('/images/cards/grain-small.png'); }
    &.ore { background-image: url('/images/cards/ore-small.png'); }
    &.cloth { background-image: url('/images/cards/cloth-small.png'); }
    &.coin { background-image: url('/images/cards/coin-small.png'); }
    &.offer-opponents { background-image: url('/images/cards/offer-opponents-small.png'); }
    &.paper { background-image: url('/images/cards/paper-small.png'); }
}

// .hand-card-small-2, .hand-card-xs-2 {
//     &.lumber { background-image: url('/images/cards/lumber-small-2.png');}
//     &.brick { background-image: url('/images/cards/brick-small-2.png'); }
//     &.wool { background-image: url('/images/cards/wool-small-2.png'); }
//     &.grain { background-image: url('/images/cards/grain-small-2.png'); }
//     &.ore { background-image: url('/images/cards/ore-small-2.png'); }
//     &.cloth { background-image: url('/images/cards/cloth-small-2.png'); }
//     &.coin { background-image: url('/images/cards/coin-small-2.png'); }
//     &.offer-opponents { background-image: url('/images/cards/offer-opponents-small-2.png'); }
//     &.paper { background-image: url('/images/cards/paper-small-2.png'); }
// }

.hand-card-small-2, .hand-card-xs-2 {
    &.lumber { background-image: url('/images/cards/lumber-small-2.png'); background-color: $lumber; }
    &.brick { background-image: url('/images/cards/brick-small-2.png'); background-color: $brick; }
    &.wool { background-image: url('/images/cards/wool-small-2.png'); background-color: $wool; }
    &.grain { background-image: url('/images/cards/grain-small-2.png'); background-color: $grain; }
    &.ore { background-image: url('/images/cards/ore-small-2.png'); background-color: $ore; }
    &.cloth { background-image: url('/images/cards/cloth-small-2.png'); background-color: $wool; }
    &.coin { background-image: url('/images/cards/coin-small-2.png'); background-color: $ore; }
    &.paper { background-image: url('/images/cards/paper-small-2.png'); background-color: $lumber; }
    &.offer-opponents { background-image: url('/images/cards/offer-opponents-small-2.png'); }
    background-size: 91%;
    background-position: center center;
    border-radius: 4px;
}

.scoreboard {
    width: 480px;
    user-select: none;
}







.opponent-dice-wrapper {
    float: left;
    padding-top: 14px;
    // padding-left: 6px;
    padding-left: 0px;
}

.opponent-dice-wrapper .dice {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    padding-left: 0px;
    margin-right: 0px;
    margin-left: 6px;
}

.opponent-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 40px;
    // width: 400px;
    min-width: 340px;
    border-left: 4px solid $white;
    user-select: none;

    &.orange { border-color: $orange; }
    &.white { border-color: $white; }
    &.red { border-color: $red; }
    &.blue { border-color: $blue; }
    &.green { border-color: $green; }
    &.brown { border-color: $brown; }
    &.pink { border-color: $pink; }

    .opponent-name {
        color: $desert;
        position: relative;
        top: -14px;
        user-select: none;
    }
}

.opponent-points {
    float: left;
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    user-select: none;
    height: 58px;
    &::before {
        width: 10px;
        height: 10px;
        background-color: $yellow;
        content: '';
        position: absolute;
        margin-top: 24px;
        margin-left: -14px;
        border-radius: 50%;
        user-select: none;
    }
}

.opponent-cards-wrapper {
    margin-left: 32px;
    margin-bottom: 16px;
    margin-top: -6px;
    width: 100%;
    display: block;
    height: 50px;

    .opponent-cards-hover .card-count {
        display: none;
        user-select: none;
        position: relative;
        font-size: 9px;
        color: $textCoin;
        right: -7px;
        top: 42px;
        text-align: left;
        font-family: 'IBM Plex Mono', monospace;

        &.safe {
            color: $cloth;
        }
    }
    .opponent-cards-hover:hover .card-count {
        display: block;
    }
}

.opponent-progress-cards-wrapper {
    height: 60px;
}

.clear-both {
    clear: both;
}

.opponent-stop-rendering-cards {
    font-size: 9px;
    color: $desert;
    position: relative;
    left: -25px;
    top: 21px;
}

.hand-card-opponent {
    width: 40px;
    height: 54px;
    background-size: contain;
    background-repeat: no-repeat;
    border: 3px solid $desert;
    border-radius: 2px;
    float: left;
    margin-left: -30px;
}

.progress-card-opponent.back {
    width: 38px;
    height: 53px;
    background-size: contain;
    border: 2px solid $gray;
    border-radius: 3px;
    float: right;
    margin-right: -18px;
}

.mr-0 {
    margin-right: 0px;
}

.progress-card.back {
    width: 67px;
    height: 93px;
    background-size: contain;
    border: 2px solid $gray;
    border-radius: 4px;
    float: left;
    margin-right: 6px;
    margin-top: 4px;
    transition: all .3s ease;
    &:hover {
        margin-top: 0px;
    }
}

.point-card-opponent {
    width: 38px;
    height: 53px;
    background-size: contain;
    border: 2px solid $gray;
    border-radius: 3px;
    float: left;
    margin-right: 3px;
    position: relative;
}

.knights-count {
    position: absolute;
    left: 4px;
    top: 2px;
    font-size: 9px;
    display: none;
}

.point-cards-container-opponent .played-knight:last-of-type {
    .knights-count {
        display: block;
    }
}

.relative {
    position: relative;
}

.buy-progress-card-btn {
    width: 142px;
    height: 38px;
}

.progress-card-3d {
    width: 39px;
    height: 53px;
    top: -12px;
    left: 5px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
}

.progress-card-3d.back.basic {
    background-image: url('/images/cards/basic-progress-3d.png');
}

.progress-card-3d.back.paper {
    background-image: url('/images/cards/paper-progress-3d.png');
}

.progress-card-3d.back.coin {
    background-image: url('/images/cards/coin-progress-3d.png');
}

.progress-card-3d.back.cloth {
    background-image: url('/images/cards/cloth-progress-3d.png');
}


.progress-card-opponent.back.basic, .progress-card.back.basic  {
    background-image: url('/images/cards/basic-progress.png');
}

.progress-card-opponent.back.cloth, .progress-card.back.cloth {
    background-image: url('/images/cards/cloth-progress.png');
}

.progress-card-opponent.back.coin, .progress-card.back.coin {
    background-image: url('/images/cards/coin-progress.png');
}

.progress-card-opponent.back.paper, .progress-card.back.paper  {
    background-image: url('/images/cards/paper-progress.png');
}

.opponent-progress-cards-container {
    float: left;
}

.point-cards-container-opponent {
    margin-right: 27px;
}

.point-card-opponent.played-knight, .point-card.played-knight {
    background-image: url('/images/cards/played-knight.png');
    margin-right: -24px;
}

.point-card-opponent.point, .point-card.point {
    background-image: url('/images/cards/point.png');
    margin-right: -24px;
}

.point-card-opponent.war, .point-card.war {
    background-image: url('/images/cards/war-point.png');
    margin-right: -24px;
}
.point-card-opponent.island, .point-card.island {
    background-image: url('/images/cards/island-point.png');
    margin-right: -24px;
}
.point-card-opponent.island {
    background-size: cover;
}
.point-card-opponent.paper, .point-card.paper {
    background-image: url('/images/cards/paper-point.png');
    margin-right: -24px;
}
.point-card-opponent.coin, .point-card.coin {
    background-image: url('/images/cards/coin-point.png');
    margin-right: -24px;
}
.point-card-opponent.largest-army, .point-card.largest-army {
    background-image: url('/images/cards/largest-army.png');
}
.point-card-opponent.longest-road, .point-card.longest-road {
    background-image: url('/images/cards/longest-road.png');
}
.point-card-opponent.merchant, .point-card.merchant {
    background-image: url('/images/cards/merchant.png');
}
.point-card-opponent.metropolis-cloth, .point-card.metropolis-cloth {
    background-image: url('/images/cards/metropolis-cloth.png');
}
.point-card-opponent.metropolis-coin, .point-card.metropolis-coin {
    background-image: url('/images/cards/metropolis-coin.png');
}
.point-card-opponent.metropolis-paper, .point-card.metropolis-paper {
    background-image: url('/images/cards/metropolis-paper.png');
}

.waiting-for-opponent {
    float: left;
    margin-top: 24px;
    margin-left: 9px;
}

.active-opponent {
    position: relative;
    top: -8px;
    left: 2px;
}

.opponent-incoming-hand-cards, .opponent-outgoing-hand-cards {
    position: relative;
    top: 14px;
    left: 8px;
    transition: all .3s ease;
}

.incoming-outgoing-wrapper {
    width: 410px;
}

.progress-wrapper-opponent {
    height: 80px;
    width: 192px;
    position: relative;
}

.progress-row {
    width: 100%;
    height: 30px;
    padding-top: 2px;
    transition: all .3s ease;

    &.metropolis-cloth { top: 7px; }
    &.metropolis-coin { top: 37px; }
    &.metropolis-paper { top: 67px; }
}

.main-player-wrapper .progress-row {
    cursor: default;
}

.main-player-wrapper .progress-row:hover {
    // background: $lightGray;
    transition: all ease-in-out .2s;
    transform: scale(1.02);
}

.progress-row .metropolis {
    position: absolute;
    margin-top: 3px;
}
.metropolis-level-4 {
    left: 140px;
}

.metropolis-level-5 {
    left: 167px;
}

.opacity-30 {
    opacity: 0.3;
}

.metropolis-owner-level-4 {
    left: -60px;
    margin-top: 5px !important;
    &.opponent {
        left: -20px;
        margin-top: 4px !important;
    }
}

.metropolis-owner-level-4-unflat {
    left: -69px;
}

.metropolis-owner-level-5 {
    left: -25px;
    margin-top: 5px !important;
    &.opponent {
        left: -20px;
        margin-top: 4px !important;
    }
}

.upgrade-cost {
    position: absolute;
    font-size: 12px;
    line-height: 27px;
    left: -22px;
    margin-top: 0px;
    .symbol {
        position: absolute;
        top: 4px;
        left: -19px;
    }
}





.opponent-trade-offer-wrapper {
    position: absolute;
    bottom: 302px;
    // bottom: 412px;
    right: 36px;
}

.opponent-trade-offer-wrapper .trade-actions {
    position: absolute;
    right: -8px;
    top: -40px;
}

.opponent-trade-offer-wrapper .trade-actions .icon-wrapper {
    float: right;
    width: 28px;
    height: 28px;
    background-color: $textCoin;
    color: $gray;
    margin-left: 7px;
    line-height: 30px;
    text-align: center;
    transition: all .15s ease;
}
.opponent-trade-offer-wrapper .trade-actions .icon-wrapper:hover {
    background: #c7b388;
}

.main-player-points-wrapper {
    text-align: right;
    position: fixed;
    bottom: 270px;
    right: 36px;
    user-select: none;
}

.score-board-points {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 20px;
    user-select: none;
}

.main-player-points {
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    margin-left: 20px;
    user-select: none;
    position: relative;

    .play-to-points {
        position: absolute;
        bottom: 5px;
        right: -23px;
        font-size: 12px;
    }
    .play-to-999 {
        right: -31px;
    }

    &::before {
        width: 10px;
        height: 10px;
        background-color: $yellow;
        content: '';
        position: absolute;
        margin-top: 24px;
        margin-left: -14px;
        border-radius: 50%;
    }
}

.main-player-wrapper {
    position: fixed;
    z-index: 9999;
    right: 30px;
    bottom: 24px;
}

.hand-cards-wrapper {
    position: fixed;
    bottom: 117px;
    right: 34px;
    height: 92px;
}

.hand-cards-container {
    margin-left: 46px;
    display: inline-block;
    .hand-card-wrapper,
    .hand-card-wrapper > span {
        float: right;
    }
}

.hand-card {
    width: 66px;
    height: 93px;
    // background-size: contain;
    // border: 4px solid $gray;
    border-radius: 4px;
    float: right;
    margin-left: -47px;
    margin-top: 0px;
}

.hand-card-2 {
    width: 66px;
    height: 93px;
    background-size: contain;
    border: 2px solid $gray;
    border-radius: 6px;
    float: right;
    margin-left: -47px;
    margin-top: 0px;
}

.progress-cards-wrapper {
    position: fixed;
    bottom: -28px;
    right: 252px;
}
.progress-cards-wrapper-unflat {
    right: 271px;
}

.hand-cards {
    padding-right: 46px;
    padding-top: 20px;
}

.progress-wrapper {
    height: 80px;
    width: 192px;
    position: absolute;
    bottom: 0px;
    right: -25px;
    user-select: none;
}

.card-small {
    width: 67px;
    height: 93px;
    background-size: contain;
    /* background-repeat: no-repeat; */
    // border: 4px solid $gray;
    border-radius: 4px;
    float: left;
    margin-right: 6px;
    margin-top: 4px;
    transition: all .3s ease;
    background-color: transparent;
    &:hover {
        margin-top: 0px;
    }
}

.waiting-for-main-player {
    float: left;
    margin-top: 20px;
    margin-right: 6px;
}

.active-main-player {
    position: relative;
    top: -8px;
    left: 2px;
}

.point-cards-container {
    float: left;
    padding-right: 30px;
    margin-right: 27px;

    .point-card {
        width: 93px;
        height: 128px;
        background-size: contain;
        border-radius: 8px;
        float: left;
        margin-right: 3px;
        border: 5px solid $gray;
        margin-right: -30px !important;
    }
}

.point-cards-container {
    .point-card.merchant, .point-card.longest-road {
        // margin-left: 40px;
        margin-left: 10px;
    }
}

.progress-card-wrapper {
    width: 92px;
    height: 127px;
    position: relative;
    float: right;
    margin-right: 10px;
    cursor: default;
}

.progress-card-info-wrapper {
    margin-bottom: 40px;
    display: block !important;
    float: left !important;
}

.progress-card {

    .label {
        text-transform: uppercase;
        font-size: 9px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 1px;
        height: 40px;
        width: inherit;
        padding-left: 5px;
        padding-right: 5px;
        display: table-cell;
        vertical-align: middle;
        user-select: none;
    }

    &.front {
        width: 92px;
        height: 127px;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 4px;
        font-family: 'Montserrat', sans-serif;
        cursor: default;
        position: absolute;
        bottom: -41px;
        bottom: 0px;
        overflow: hidden;
        transition: bottom .3s ease;

        &.basic { background-color: $desert; }
        &.coin { background-color: $coin; }
        &.paper { background-color: $lumber; }
        &.basic .label { color: $gray; }
        &.cloth .label { color: $coin; }
        &.coin .label { color: $textCoin; }
        &.paper .label { color: $textPaper; }

        &:hover {
            bottom: 8px;
        }
    }
}


.progress-card-content {
    position: relative;
    left: -0px;
    width: 184px;
    transition: all .5s ease;
}

.progress-card-highlighted {
    animation: highlightedProgressCards 2s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}

@keyframes highlightedProgressCards {
    0% { top: 0px; }
    20% { top: -38px; }
    100% { top: -38px; }
  }


.progress-card-show-info {
    left: -92px;
}

.progress-card-main {
    float: left;
    width: 92px;
}

.progress-card-info {
    float: left;
    width: 92px;
    height: 127px;
    padding: 12px 10px;
    font-size: 8px;
    overflow-y: scroll;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;

    &.basic { color: $gray; }
    &.cloth { color: #000; }
    &.coin { color: $textCoin; }
    &.paper { color: $textPaper; }
}

.progress-card-active {
    /* bottom: 34px !important; */
    bottom: 68px !important;
}

.progress-card .image-wrapper {
    width: 80px;
    height: 80px;
    background: $gray;
    margin: -3px auto;
    position: relative;
}

.progress-card.front.cloth {
    background-color: $cloth;
}

.progress-image {
    width: 80px;
    height: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 0px;

    &.point, &.point-2 { background-image: url('/images/cards/progress/point.png'); }
    &.knight, &.knight-2 { background-image: url('/images/cards/progress/knight.png'); }
    &.monopoly, &.monopoly-2 { background-image: url('/images/cards/progress/monopoly.png'); }
    &.monopoly-2 { background-image: url('/images/cards/progress/monopoly-2.png'); }
    &.year-of-plenty, &.year-of-plenty-2 { background-image: url('/images/cards/progress/year-of-plenty.png'); }
    &.year-of-plenty-2 { background-image: url('/images/cards/progress/year-of-plenty-2.png'); }
    &.merchant, &.merchant-2 { background-image: url('/images/cards/progress/merchant.png'); }
    &.resource-monopoly, &.resource-monopoly-2 { background-image: url('/images/cards/progress/resource-monopoly.png'); }
    &.resource-monopoly-2 { background-image: url('/images/cards/progress/resource-monopoly-2.png'); }
    &.trade-monopoly, &.trade-monopoly-2 { background-image: url('/images/cards/progress/trade-monopoly.png'); }
    &.trade-monopoly-2 { background-image: url('/images/cards/progress/trade-monopoly-2.png'); }
    &.master-merchant, &.master-merchant-2 { background-image: url('/images/cards/progress/master-merchant.png'); }
    &.master-merchant-2 { background-image: url('/images/cards/progress/master-merchant-2.png'); }
    &.merchant-fleet, &.merchant-fleet-2 { background-image: url('/images/cards/progress/merchant-fleet.png'); }
    &.merchant-fleet-2 { background-image: url('/images/cards/progress/merchant-fleet-2.png'); }
    &.commercial-harbor, &.commercial-harbor-2 { background-image: url('/images/cards/progress/commercial-harbor.png'); }
    &.commercial-harbor-2 { background-image: url('/images/cards/progress/commercial-harbor-2.png'); }
    &.diplomat, &.diplomat-2 { background-image: url('/images/cards/progress/diplomat.png'); }
    &.wedding, &.wedding-2 { background-image: url('/images/cards/progress/wedding.png'); }
    &.spy, &.spy-2 { background-image: url('/images/cards/progress/spy.png'); }
    &.saboteur, &.saboteur-2 { background-image: url('/images/cards/progress/saboteur.png'); }
    &.bishop, &.bishop-2 { background-image: url('/images/cards/progress/bishop.png'); }
    &.medecine, &.medecine-2 { background-image: url('/images/cards/progress/medecine.png'); }
    &.medecine-2 { background-image: url('/images/cards/progress/medecine-2.png'); }
    &.inventor, &.inventor-2 { background-image: url('/images/cards/progress/inventor.png'); }
    &.mining, &.mining-2 { background-image: url('/images/cards/progress/mining.png'); }
    &.mining-2, &.mining-2-2 { background-image: url('/images/cards/progress/mining-2.png'); }
    &.irrigation, &.irrigation-2 { background-image: url('/images/cards/progress/irrigation.png'); }
    &.irrigation-2, &.irrigation-2-2 { background-image: url('/images/cards/progress/irrigation-2.png'); }
    &.road-building, &.road-building-2 { background-image: url('/images/cards/progress/road-building.png'); }
    &.crane, &.crane-2 { background-image: url('/images/cards/progress/crane.png'); }
    &.crane-2 { background-image: url('/images/cards/progress/crane-2.png'); }
    &.alchemist, &.alchemist-2 { background-image: url('/images/cards/progress/alchemist.png'); }
}

.progress-card-knight {
    position: absolute;
    width: 15px;
}

.progress-card-road {
    position: absolute;
    height: 30px;
}

.progress-card-city {
    position: absolute;
    width: 28px;
}

.progress-card-actions {
    width: 100%;
    height: 26px;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 34px;

    &.basic {
        color: $gray;
        .icon-wrapper {
            background: $desert;
        }
    }
    &.cloth {
        color: $textCloth;
        .icon-wrapper {
            background: $cloth;
        }
    }
    &.coin {
        color: $textCoin;
        .icon-wrapper {
            background: $coin;
        }
    }
    &.paper {
        color: $textPaper;
        .icon-wrapper {
            background: $paper;
        }
    }
}

.bg-cloth {
    color: $textCloth;
    background: $cloth;
    font-weight: 500;
}
.bg-coin {
    color: $textCoin;
    background: $coin;
    font-weight: 500;
}
.bg-paper {
    color: $textPaper;
    background: $paper;
    font-weight: 500;
}
.bg-basic {
    color: $gray;
    background: $desert;
    font-weight: 500;
}

.icon-wrapper {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
    line-height: 24px;
}

.road-action-spot-wrapper {
    position: absolute;
    left: -100px;
}

.road-actions-wrapper {
    background: #383832;
    width: 200px;
    position: relative;
    top: 4px;
    margin-left: 16px;
    cursor: pointer;
    user-select: none;
}

.road-actions-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    left: 88px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid #383832;
    border-top: 0px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.action {
    display: block;
    position: relative;
    bottom: 0;
    height: 48px;
}
.action:hover {
    background: #333;
}

.road-item {
    margin-left: 8px;
    margin-top: 2px;
}

.knight-item {
    top: 5px;
    left: 3px;
}


.action-spot-wrapper {
    position: absolute;
    left: -100px;
}

.actions-wrapper {
    background: #383832;
    width: 210px;
    position: relative;
    top: 4px;
    margin-left: 6px;
    cursor: pointer;
    user-select: none;
}

.actions-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    left: 98px;
    bottom: 100%;
    width: 0;
    height: 0;
    border-bottom: 10px solid #383832;
    border-top: 0px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.action {
    display: block;
    position: relative;
    bottom: 0;
    height: 48px;
    transition: .3s ease-in-out;
    border: 2px solid transparent;
}

.action:hover {
    background: #333;
}

.action-confirmed {
    border: 2px solid $cloth;
}
.action-confirmed:hover {
    border: 2px solid $cloth;
}

.action .item {
    position: absolute;

    top: 8px;
    left: 7px;
}

.items-left {
    font-size: 8px;
    position: absolute;
}

.action p {
    text-transform: uppercase;
    font-size: 8px;
    line-height: 32px;
    margin-left: 40px;
}

.spot-wrapper {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -2px;
    top: -7px;
    z-index: 1;
    background-size: contain;
}

.action-cost {
    float: right;
    margin-top: 18px;
    margin-right: 15px;
}

.action-cost-2 {
    float: right;
    margin-top: 10px;
    margin-right: 12px;
}

.symbol, .symbol-2 {
    background-size: contain;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 3px;
    background-repeat: no-repeat;

    &.cloth, &.coin, &.paper  {
        width: 16px;
        height: 18px;
        margin-left: 2px;
    }

    &.knight {
        background-image: url('/images/symbols/knight.png');
        background-size: 12px 13px;
        margin-top: 2px;
    }
    &.point { background-image: url('/images/symbols/point.png'); }

    &.lumber { background-image: url('/images/symbols/lumber.png'); }
    &.ore { background-image: url('/images/symbols/ore.png'); }
    &.wool { background-image: url('/images/symbols/wool.png'); }
    &.grain { background-image: url('/images/symbols/grain.png'); }
    &.brick { background-image: url('/images/symbols/brick.png'); }
    &.goldmine { background-image: url('/images/symbols/goldmine.png'); }
    &.cloth { background-image: url('/images/symbols/cloth.png'); }
    &.coin { background-image: url('/images/symbols/coin.png'); }
    &.paper { background-image: url('/images/symbols/paper.png'); }
    &.war { background-image: url('/images/symbols/war.png'); }

    &.lumber-2, &.harbor-lumber { background-image: url('/images/symbols/lumber-2.png'); }
    &.ore-2, &.harbor-ore { background-image: url('/images/symbols/ore-2.png'); }
    &.wool-2, &.harbor-wool { background-image: url('/images/symbols/wool-2.png'); }
    &.grain-2, &.harbor-grain { background-image: url('/images/symbols/grain-2.png'); }
    &.brick-2, &.harbor-brick { background-image: url('/images/symbols/brick-2.png'); }
    &.goldmine-2 { background-image: url('/images/symbols/goldmine.png'); }
    &.knight-2 { background-image: url('/images/symbols/knight.png'); }
    &.point-2 { background-image: url('/images/symbols/point.png'); }
    &.knight-2, &.point-2 {
        width: 14px;
        height: 14px;
        margin-right: 12px !important;
        margin-left: 0px !important;
        margin-bottom: -10px;
        top: 2px !important;
        left: 4px !important;
    }
    &.cloth-2 { background-image: url('/images/symbols/cloth-2.png'); }
    &.coin-2 { background-image: url('/images/symbols/coin-2.png'); }
    &.paper-2 { background-image: url('/images/symbols/paper-2.png'); }
    &.harbor-all { background-image: url('/images/symbols/harbor-all-2.png'); }

    &.lumber-3 { background-image: url('/images/symbols/lumber-3.png'); }
    &.ore-3 { background-image: url('/images/symbols/ore-3.png'); }
    &.wool-3 { background-image: url('/images/symbols/wool-3.png'); }
    &.grain-3 { background-image: url('/images/symbols/grain-3.png'); }
    &.brick-3 { background-image: url('/images/symbols/brick-3.png'); }
    &.goldmine-3 { background-image: url('/images/symbols/goldmine.png'); }

    &.metropolis { background-image: url('/images/symbols/metropolis.png'); }

    &.basic-progress { background-image: url('/images/symbols/basic-progress.png'); }
    &.cloth-progress { background-image: url('/images/symbols/cloth-progress.png'); }
    &.coin-progress { background-image: url('/images/symbols/coin-progress.png'); }
    &.paper-progress { background-image: url('/images/symbols/paper-progress.png'); }
}

.symbol-2 {
    width: 28px;
    height: 28px;
    margin-right: -2px;
}

.symbol-tile {
    // position: relative;
    // left: 60px;
    // // top: -77px;
    // top: -137px;
    // width: 24px;
    // height: 24px;

    position: relative;
    left: 62px;
    top: -135px;
    width: 22px;
    height: 22px;
}

.symbol-progress {
    width: 28px;
    height: 28px;
    top: 0px !important;
    left: -32px !important;
}

.symbol-statuses {
    width: 28px;
    height: 28px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: -10px;
    top: -4px !important;
    left: -4px !important;
}

.symbol-bank-trade {
    width: 28px;
    height: 28px;
    margin-top: -7px;
    margin-right: -4px;
}

.symbol-offset-unflat {
    margin-right: 29px;
}

.circle-text {
    width: 418px;
    position: absolute;
    top: 31px;
    left: 30px;
}

.circle-text textPath {
    font-size: 62px;
    font-family: monospace;
    // font-family: 'IBM Plex Mono', monospace;
    // font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	letter-spacing: 20px;
    // background: #333;
}

.circle-text-lumber textPath {
    font-size: 64px;
}

.close-action-menu {
    position: absolute;
    right: 0;
    z-index: 9;
    font-size: 6px;
    padding: 5px 6px;
    color: #CCC;
}
.close-action-menu:hover {
    background: #292929;
}

.action-spot {
    position: absolute;
    z-index: 5;
}

.click-tile {
    position: absolute;
    z-index: 999;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    top: 25px;
    left: 25px;
    z-index: 999;
    // background: rgba(255, 0, 0, 0.7);
}

.spot-0 {
    top: -9px;
    left: 61px;
}

.action-spot-0 {
    top: 19px;
    left: 61px;
}

.spot-1 {
    top: 26px;
    left: 125px;
}

.action-spot-1 {
    top: 56px;
    left: 125px;
}

.spot-2 {
    top: 101px;
    left: 125px;
}

.action-spot-2 {
    top: 131px;
    left: 125px;
}

.spot-3 {
    top: 137px;
    left: 61px;
}

.action-spot-3 {
    top: 167px;
    left: 61px;
}

.spot-4 {
    top: 101px;
    left: -3px;
}

.action-spot-4 {
    top: 131px;
    left: -3px;
}

.spot-5 {
    top: 26px;
    left: -3px;
}

.action-spot-5 {
    top: 56px;
    left: -3px;
}

@keyframes highlightedRoadSpotEmptyAnimation {
  0% { transform: scale(0.9) }
  25% { transform: scale(1.2) }
  50% { transform: scale(0.9) }
  75% { transform: scale(1.2) }
  100% { transform: scale(0.9) }
}

.road-spot-0 {
    top: 8px;
    transform: rotate(30deg);
    left: 91px;
}

.road-action-spot {
    position: absolute;
    z-index: 5;
}

.road-action-spot-0 {
    top: 32px;
    left: 91px;
}

.road-action-spot-1 {
    top: 91px;
    left: 125px;
}

.road-action-spot-2 {
    top: 144px;
    left: 96px;
}
.road-action-spot-3 {
    top: 144px;
    left: 26px;
}
.road-action-spot-4 {
    top: 90px;
    left: -3px;
}
.road-action-spot-5 {
    top: 34px;
    left: 32px;
}
.road-action-spot-6 {
    top: -18px;
    left: 61px;
}
.road-action-spot-7 {
    top: 36px;
    left: 159px;
}
.road-action-spot-8 {
    top: 143px;
    left: 154px;
}

.road-spot-1 {
    top: 60px;
    left: 122px;
    transform: rotate(90deg);
}

.road-spot-2 {
    top: 119px;
    transform: rotate(-30deg);
    left: 96px;
}

.road-spot-3 {
    top: 118px;
    transform: rotate(30deg);
    left: 26px;
}

.road-spot-4 {
    top: 59px;
    left: -6px;
    transform: rotate(90deg);
}

.road-spot-5 {
    top: 9px;
    transform: rotate(-30deg);
    left: 32px;
}

.road-spot-6 {
    top: -51px;
    transform: rotate(90deg);
    left: 58px;
}

.road-spot-7 {
    top: 9px;
    transform: rotate(-30deg);
    left: 159px;
}

.road-spot-8 {
    top: 119px;
    transform: rotate(30deg);
    left: 155px;
}

.actionTileInfo {
    color: #000 !important;
}

.player-info {
    position: absolute;
    background-color: $gray;
    padding: 12px 30px;
    z-index: 10;
    width: 252px;
    height: 120px;
    z-index: 999999999999999;

    .total {
        user-select: none;
        position: absolute;
        font-size: 9px;
        font-weight: 600;
        color: $coin;
        text-align: right;
        font-family: 'IBM Plex Mono', monospace;
        background: $textCoin;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        text-align: center;
        z-index: 9999;
    }
}

.opponent-player-info {
    margin-left: -11px;
    margin-top: 74px;
}

.main-player-info {
    position: absolute;
    // bottom: 351px;
    bottom: 457px;
    right: 0px;
    border-radius: 2px;
}

.piece-labels {
    position: absolute;
    user-select: none;
    font-family: 'IBM Plex Mono', monospace !important;
    font-size: 12px;
    color: $textCoin !important;
}

.costs-and-points {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    min-width: 720px;
    min-height: 440px;
    background-position: 0px 40px;
    z-index: 101;
    background-size: contain;
    padding-bottom: 30px;
    border-radius: 12px;

    &.orange { background-image: url('/images/cards/costs-and-points-orange.png'); }
    &.white { background-image: url('/images/cards/costs-and-points-white.png'); }
    &.red { background-image: url('/images/cards/costs-and-points-red.png'); }
    &.blue { background-image: url('/images/cards/costs-and-points-blue.png'); }
    &.green { background-image: url('/images/cards/costs-and-points-green.png'); }
    &.brown { background-image: url('/images/cards/costs-and-points-brown.png'); }
    &.pink { background-image: url('/images/cards/costs-and-points-pink.png'); }
    &.basic { background-image: url('/images/cards/costs-and-points-basic.png'); }

    .head {
        margin: 30px;
        border-bottom: 2px solid $textCoin;
        height: 32px;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        color: $textCoin;
        padding-right: 50px;
        .close-modal {
            position: absolute;
            right: 0;
            top: 0;
            width: 22px;
            height: 22px;
            cursor: pointer;
            .line {
                width: 16px;
                height: 1px;
                background-color: $textCoin;
                position: absolute;
                top: 10px;
                right: 0px;
            }
            .line-1 {
                transform: rotate(45deg);
            }
            .line-2 {
                transform: rotate(-45deg);
            }
            &:hover .line {
                width: 18px;
                top: 10px;
                right: -1px;
            }
        }
    }
}

.costs-and-points-ck {
    // position: fixed !important;
    top: 250px !important;
    // top: 200px !important;
}

.basic-cover {
    background-image: url('/images/cards/basic-cover.png');
    width: 217px;
    height: 248px;
    background-size: 100%;
    margin-top: -1px;
}

.ck-cover {
    background-image: url('/images/cards/ck-cover.png');
    width: 212px;
    height: 316px;
    background-size: 100%;
    margin-top: -2px;
}

.text-coin {
    color: $textCoin;
}

.text-cloth {
    color: $textCloth;
}

.text-water {
    color: #384756;
}

.text-yellow {
    color: $yellow;
}

.toggle-guides-trigger {
    float: left;
    background: $gray;
    width: 24px;
    height: 24px;
    line-height: 22px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    margin-top: 17px;
    margin-right: 5px;
    color: $textCoin;
    border: 1px solid #535249;
}

.x-offset {
    margin-left: 64px;
}

.preview-x-offset {
    margin-left: 23px;
}

.map-editor-nav-tile {
    width: 100px;
    height: 100px;
    background-size: 100px 100px;
    position: relative;
    margin-top: -55px;
    transform: rotate(30deg);
    border-radius: 50%;
    cursor: pointer;

    // &.lumber { background-image: url('/images/tiles/lumber.png'); }
    // &.brick { background-image: url('/images/tiles/brick.png'); }
    // &.wool { background-image: url('/images/tiles/wool.png'); }
    // &.grain { background-image: url('/images/tiles/grain.png'); }
    // &.ore { background-image: url('/images/tiles/ore.png'); }

    // &.desert { background-image: url('/images/tiles/desert.png'); }
    // &.water { background-image: url('/images/tiles/water.png'); }

    // &.goldmine { background-image: url('/images/tiles/goldmine.png'); }
    &.empty { background-image: url('/images/tiles/empty.png'); background-size: 142px 142px; }
    &.no-initial-build { background-image: url('/images/tiles/no-initial-build.png'); background-size: 140px 140px; }
    &.hidden-tile, &.build-blocked, &.artist { background-image: url('/images/tiles/empty.png'); }
    &.random-resource { background-image: url('/images/tiles/random-resource.png'); background-size: 142px 142px; }
    &.random-resource-rotated { background-image: url('/images/tiles/random-resource-rotated.png'); background-size: 142px 142px; }
    &.random-resource-gold { background-image: url('/images/tiles/random-resource-gold.png'); background-size: 142px 142px; }
    &.random-resource-gold-rotated { background-image: url('/images/tiles/random-resource-gold-rotated.png'); background-size: 142px 142px; }
    &.surprise-me { background-image: url('/images/tiles/surprise-me.png'); background-size: 142px 142px; }
    &.random-resource-no-desert { background-image: url('/images/tiles/random-resource-no-desert.png'); background-size: 142px 142px; }
    &.random-resource-no-desert-rotated { background-image: url('/images/tiles/random-resource-no-desert-rotated.png'); background-size: 142px 142px; }
    &.random-harbor { background-image: url('/images/tiles/water.png'); }

    // &.harbor-lumber { background-image: url('/images/tiles/harbor-lumber.png'); }
    // &.harbor-brick { background-image: url('/images/tiles/harbor-brick.png'); }
    // &.harbor-wool { background-image: url('/images/tiles/harbor-wool.png'); }
    // &.harbor-grain { background-image: url('/images/tiles/harbor-grain.png'); }
    // &.harbor-ore { background-image: url('/images/tiles/harbor-ore.png'); }
    // &.harbor-all { background-image: url('/images/tiles/harbor-all.png'); }

    &.empty, &.random-resource, &.random-resource-gold, &.random-resource-no-desert, &.random-resource-rotated, &.random-resource-gold-rotated, &.random-resource-no-desert-rotated, &.surprise-me {
        background-size: 98px 98px;
    }
}

.number-dots-wrapper {
    width: 50px;
    height: 16px;
    position: absolute;
    // top: 91px;
    // top: 102px;
    left: 51px;
    // top: 65px;
    top: 85px;
    text-align: center;

    .number-dot {
        width: 3px;
        height: 3px;
        border-radius: 1.5px;
        background-color: $gray;
        display: inline-block;
        margin: 0px 1.5px;
    }
}

.nav-tile-x-offset-right {
    margin-left: -78px;
    margin-top: -100px;
}
.nav-tile-x-offset-left {
    margin-left: 78px;
}

.map-editor-nav-wrapper {
    position: absolute;
    margin-top: 80px;
    margin-right: 20px;
    right: 0;
    width: 200px;
}

.nav-tile-label {
    position: absolute;
    z-index: 99;
    // background: $gray;
    color: $textCoin;
    // padding: 2px 2px;
    margin-left: 15%;
    margin-right: 15%;
    bottom: 17px;
    font-size: 9px;
    width: 70%;
    transform: rotate(-30deg);
    left: 15px;
}

.bg-gray {
    background: $gray;
}

.map-editor-active-tile {
    border: 2px solid #FFF;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 41px;
    left: 41px;
}

.editor-numbers-wrapper {
    position: absolute;
    left: -144px;
    top: 47px;
    cursor: pointer;
}

.map-editor-number {
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border: 1px solid $textCoin;
    border-radius: 50%;
    margin-bottom: 6px;
    font-size: 11px;
    user-select: none;
    background: $gray;
}

.text-small {
    font-size: 9px !important;
}

.text-bold {
    font-weight: bold;
}

.active-map-editor-number {
    border: 1px solid $textCloth;
}

.container-fluid {
    position: absolute;
}

.map-wrapper {
    position: absolute;
    left: 148px;
    top: -130px;
    width: 1520px;
    height: 1300px;
}

.editor-tiles-wrapper {
    position: absolute;
    top: -60px;
    left: -160px;
}

.text-coin {
    color: $textCoin;
}


.text-gray {
    color: $gray;
}

.text-paper {
    color: $textPaper;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #7B1FA2;
    background-color: red;
}


/* The container */
.radio-container {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    padding-right: 20px;
    padding-top: 1px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $textCoin;
  }

  .select-none {
    user-select: none;
  }

  .click-green:active {
    color: $cloth;
  }

  .hover-underline:hover {
      text-decoration: underline;
  }

  .hover-scale {
      transition: .15s ease-in-out;
  }

  .hover-scale:hover {
    transform: scale(1.15);
  }

  .rotate-0 {
    transition: .2s ease-in-out;
    transform: rotate(-0deg);
}

  .rotate-180 {
      transition: .2s ease-in-out;
      transform: rotate(-180deg);
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    // background-color: #eee;
    border: 2px solid $textCoin;
    border-radius: 50%;
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input ~ .checkmark {
    background-color: #413f36;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .checkmark {
    background-color: $textCoin;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
//   .radio-container .checkmark:after {
//       top: 9px;
//       left: 9px;
//       width: 8px;
//       height: 8px;
//       border-radius: 50%;
//       background: $gray;
//       background: $textCoin;
//   }

/* The container */
.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 26px;
    padding-right: 20px;
    padding-top: 1px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $textCoin;
  }

  /* Hide the browser's default radio button */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .checkbox-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    // background-color: #eee;
    border: 2px solid $textCoin;
    border-radius: 0%;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkbox-checkmark {
    background-color: #413f36;
  }

  /* When the radio button is checked, add a blue background */
  .checkbox-container input:checked ~ .checkbox-checkmark {
    background-color: $textCoin;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkbox-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .checkbox-container input:checked ~ .checkbox-checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
//   .checkbox-container .checkmark:after {
//       top: 4px;
//       left: 4px;
//       width: 8px;
//       height: 8px;
//       background: $gray;
//   }

.new-game-player-wrapper {
    position: relative;
}

.fake-dropdown {
    height: 42px;
    background: $gray;
    border-radius: 0;
    border: 2px solid $textCoin;
    user-select: none;
}

.fake-dropdown-options {
    background: $gray;
    border-radius: 0;
    border: 2px solid $textCoin;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 83px;
    width: 100%;
    padding: 8px 12px;
    color: $textCoin;
    user-select: none;
    div:hover {
        text-decoration: underline;
    }
}

.fake-dropdown-content {
    position: relative;
    top: 8px;
    margin-left: 12px;
}

.new-game-avatar {
    position: absolute;
    left: -54px;
    top: 40px;
}

.change-color-dropdown {
    position: absolute;
    z-index: 10;
    background-color: $gray;
    width: 101%;
    top: 40px;
    border-left: 2px solid $textCoin;
    padding-left: 8px;
}

.new-game-select-avatar {
    float: left;
}

input[type=text], input[type=email], input[type=password] {
    height: 42px;
    background: $gray;
    color: $textCoin;
    border-radius: 0;
    border: 2px solid $textCoin;

    &:focus, &:active {
        background: $gray;
        color: $textCoin;
        box-shadow: inset 0 -1px 0 transparent;
        border: 2px solid $textCoin;
    }
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #716651;
        opacity: 1; /* Firefox */
    }
}

input[type=select] {
    background-color: $gray;
}

.custom-select {
    height: 44px;
    background: $gray !important;
    color: $textCoin;
    border: 2px solid $textCoin;
    border-radius: 0;

    &:focus, &:active {
        background: $gray;
        // color: $textCoin;
        box-shadow: inset 0 -1px 0 transparent;
        border: 2px solid $textCoin;
    }
}

.select-text-coin {
    &:focus {
        color: $textCoin;
    }
}

.select-caret {
    position: relative;
    right: 14px;
    float: right;
    top: -27px;
    font-size: 14px;
}

.select-caret-inside {
    position: relative;
    right: 14px;
    float: right;
    top: 4px;
    font-size: 14px;
    float: right;
}

.btn-lg {
    height: 48px;
    background-color: #312E25;
    &:hover {
        outline: 0;
        border: 2px solid $textCoin;
    }
}

.btn-primary {
    &:hover {
        outline: 0;
        border: 2px solid $textCoin;
    }
    &:focus, &:active {
        background: $gray !important;
        border: 2px solid $textCoin !important;
        outline: 0 !important;
        box-shadow: 0 !important;
    }
}

.map-preview {
    height: 190px;
    position: relative;
    left: -70px;
    transform: scaleX(0.65) scaleY(0.5) perspective(600px) rotateX(30deg);
    top: -60px;
    overflow-y: visible;
}

.save-map-wrapper {
    position: relative;
    left: -50px;
    top: 16px;
}

.saved-game-wrapper {
    border: 2px solid $textCoin;
    color: $textCoin;
    padding: 8px;
    margin-bottom: 20px;
    background-color: #312E25;
    width: 100%;
    line-height: 28px;
    &:hover {
        background-color: #45443E;
    }
}

.saved-game-point {
    font-size: 18px;
    &::before {
        width: 10px;
        height: 10px;
        background-color: $yellow;
        content: '';
        position: absolute;
        margin-top: 9px;
        margin-left: -14px;
        border-radius: 50%;
        user-select: none;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.spin-on-hover {
    transition: transform .5s ease;
    &:hover {
        transform: rotate(-45deg);
    }
}

.all-progress-cards {
    position: fixed;
    left: 50%;
    top: 614px;
    transform: translate(-50%,-50%);
    width: 720px;
    min-height: 230px;
    background: $gray;
    border-radius: 12px;
    padding-left: 30px;
    padding-top: 30px;
    z-index: 9999999999999999;
    overflow: hidden;

    .head {
        margin-right: 30px;
        margin-bottom: 18px;
        border-bottom: 2px solid $textCoin;
        height: 32px;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        color: $textCoin;
        padding-right: 50px;
        .close-modal {
            position: absolute;
            right: 0;
            top: 0;
            width: 22px;
            height: 22px;
            cursor: pointer;
            .line {
                width: 16px;
                height: 1px;
                background-color: $textCoin;
                position: absolute;
                top: 10px;
                right: 0px;
            }
            .line-1 {
                transform: rotate(45deg);
            }
            .line-2 {
                transform: rotate(-45deg);
            }
            &:hover .line {
                width: 18px;
                top: 10px;
                right: -1px;
            }
        }
    }
}

.all-progress-cards-inner {
    padding-top: 8px;
    padding-bottom: 12px;
    overflow-x: scroll;
    width: 100%;
}

.all-progress-cards-wide {
    width: 2570px;
}

.progress-card-amount {
    position: absolute;
    z-index: 99999999;
    display: block;
    border-radius: 16px;
    height: 18px;
    padding: 0px 6px;
    font-size: 11px;
    margin: 0 auto;
    bottom: -30px;
    left: 30px;
    font-family: 'IBM Plex Mono', monospace !important;
}

.feedback {
    background-color: $textCoin;
    color: $gray;
    padding: 10px 24px;
    z-index: 999999 !important;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 20px;
    font-family: 'IBM Plex Mono', monospace !important;
}

.hand-cards-count {
    position: absolute;
    bottom: -24px;
    text-align: center;
    width: 100%;
    font-size: 12px;
}

.disable-dbl-tap-zoom {
    touch-action: manipulation;
}

.dice-tracker-wrapper {
    width: auto;
    position: relative;
    font-family: monospace;
    // top: -343px;
    top: -358px;
    left: 0px;
    height: 92px;
    // z-index: 999999;
    transition: .5s ease-in-out;
    opacity: 0;
    background-color: $gray;
    border-radius: 2px;
    padding: 7px 4px;

    .dice-tracker-numbers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
    }

    .dice-tracker {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 1px;
        height: 50px;
        width: 100%;
        transition: .5s ease-in-out;
        // border: 2px solid #383832;
        // border-radius: 4px;
        // gap: 3px;

        .staple {
            position: relative;
            width: 3px;
            height: 100%;
            border-right: 0px solid $gray;
            margin: 0px 6px;
        }
    }
}

.dice-tracker-wrapper-2 {
    width: auto;
    font-family: monospace;
    height: 92px;
    transition: .5s ease-in-out;
    background-color: $gray;
    border-radius: 2px;
    margin-top: 20px;

    .dice-tracker-numbers {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
    }

    .dice-tracker {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        margin-bottom: 1px;
        height: 50px;
        width: 100%;
        transition: .5s ease-in-out;
        // border: 2px solid #383832;
        // border-radius: 4px;
        // gap: 3px;

        .staple {
            position: relative;
            width: 6px;
            height: 100%;
            border-right: 0px solid $gray;
            margin: 0px 7px;
        }
    }
}

.score-board-padding {
    padding-bottom: 30px !important;
}

.loading-dots::after {
    display: inline-block;
    animation: dotty steps(1,end) 1.2s infinite;
    content: '';
    letter-spacing: 2px;
  }

  @keyframes dotty {
      0%   { content: ''; }
      25%  { content: '.'; }
      50%  { content: '..'; }
      75%  { content: '...'; }
      100% { content: ''; }
  }

@media only screen and (min-width: 990px) {
    .mobile-show {
        display: none;
    }
    .mt-3-desktop {
        margin-top: 1rem;
    }
}

// mobile
@media only screen and (max-width: 990px) {
    .new-game-player-wrapper {
        left: 50px;
        padding-right: 50px;
    }

    .attention-wrapper {
        bottom: 336px !important;
    }
    .action {
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }
    .mobile-margin {
        margin-left: 10px;
    }
    .mobile-hide {
        display: none;
    }
    .main-player-points-wrapper {
        bottom: 215px;
    }
    .opponent-trade-offer-wrapper {
        bottom: 247px;
    }
    .dice-wrapper {
        bottom: 272px;
    }
    .hand-card-small.basic {
        width: 36px;
        height: 50px;
        margin-right: 8px;
    }

    .opponent-trade-offer-wrapper .trade-actions .icon-wrapper {
        width: 2.2rem;
        height: 2.2rem;
        line-height: 2.2rem;
    }
    .opponent-trade-offer-wrapper .trade-actions {
        top: -50px;
    }
    .get-trade-selected-wrapper .player-trade-color {
        height: 105px;
    }
    .get-trade-selected-wrapper .icon-wrapper, .give-trade-selected-wrapper .icon-wrapper {
        top: 14px;
    }

    .get-trade-wrapper, .give-trade-wrapper {
        height: 60px;
    }

    .get-trade-trigger,
    .give-trade-trigger {
        display: none;
    }

    .get-trade-cards,
    .give-trade-cards {
        display: block;
    }
    .trade-actions .icon-wrapper {
        font-size: 18px;
    }
    .map-preview {
        // height: 420px;
        height: 180px;
        margin: 0 auto;
        overflow: visible;
    }
}

.live::before {
    width: 7px;
    height: 7px;
    background-color: #FF0000;
    content: '';
    position: absolute;
    margin-left: -13px;
    margin-top: 5px;
    border-radius: 50%;
    user-select: none;
}

.games-table {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    margin-top: 6px;

    .highlight-text {
        color: #C8FF2B;
    }

    th {
        font-size: 11px;
        font-weight: 400;
        color: $textCoin;
        padding: 8px 8px;
    }
    td {
        font-size: 14px;
        color: $textCoin;
        padding: 8px 8px;
        background-color: #302e28;
        border-bottom: 4px solid $gray;

    }
    td:last-child {
        // background-color: transparent;
        padding-left: 12px;
        width: 1%;
        white-space: nowrap;
    }
    .points::before {
        width: 6px;
        height: 6px;
        background-color: $yellow;
        content: '';
        position: absolute;
        margin-left: -11px;
        margin-top: 9px;
        border-radius: 50%;
        user-select: none;
    }
}

.games-table-btn {
    background-color: $textCoin;
    color: #302e28;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    transition: .3s ease;
    text-transform: uppercase;
    padding-top: 4px;
    padding-bottom: 2px;
    width: 100px;
    font-size: 11px;
    letter-spacing: .5px;
    float: right;
    &:hover {
        background-color: #E3C07A;
    }
    &.highlight {
        background-color: #C8FF2B;
        &:hover {
            background-color: #D1FF4D;
        }
    }
}

.btn-dark {
    background-color: $gray;
    color: $textCoin;
    &:hover {
        background-color: $gray;
        color: $textCoin;
    }
}

h4 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    color: $textCoin;
    font-family: 'Montserrat', sans-serif;
}

.hr {
    width: 100%;
    height: 2px;
    background-color: $textCoin;
}

.hr-gray {
    // width: 100%;
    // height: 1px;
    // background-color: #535248;
}

.game-tables-container {
    padding-left: 40px;
}

.joined-game-table {
    margin-top: 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    margin-left: 16px;
    color: $textCoin;
    tr:first-of-type {
        color: #B4F22F;
        border-bottom: 1px solid $textCoin;
    }
    tr:last-of-type {
        border-bottom: 1px solid $textCoin;
    }
    tr {
        height: 34px;
        td:first-of-type {
            padding-right: 60px;
        }
    }

    .points::before {
        width: 6px;
        height: 6px;
        background-color: $yellow;
        content: '';
        position: absolute;
        margin-left: -11px;
        margin-top: 9px;
        border-radius: 50%;
        user-select: none;
    }
}

.animated-chevrons-wrapper {
    position: relative;
    .animated-up {
        position: absolute;
        left: 5px;
        animation: animateUp 2s infinite;
    }
    .animated-down {
        position: absolute;
        left: 5px;
        animation: animateDown 2s infinite;
    }
}

.z-top {
    z-index: 999999 !important;
}

@keyframes animateUp {
    0% { opacity: 0; top: 8px; }
    50% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; top: -2px; }
}

@keyframes animateDown {
    0% { opacity: 0; top: -2px; }
    50% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; top: 8px; }
}

@media only screen and (max-width: 990px) {
    .game-tables-container {
        padding-left: 14px;
    }
    .games-table-btn {
        border-radius: 4px;
        padding-top: 8px;
        padding-bottom: 6px;
    }
}

/* Portrait */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .map-preview {
        position: relative;
        left: -106px;
        width: 460px;
        overflow: hidden;
        height: 180px;
        overflow: visible;
    }
}

.lobby-container-mobile {
    font-family: 'Montserrat', sans-serif;
    background: #302E28;
    color: $textCoin;
    border-radius: 12px;
    margin: 22px;
    padding: 12px 0px;

    .points::before {
        width: 6px;
        height: 6px;
        background-color: $yellow;
        content: '';
        position: absolute;
        margin-left: -11px;
        margin-top: 9px;
        border-radius: 50%;
        user-select: none;
    }
}

.settings-menu {
    position: fixed;
    right: 10px;
    top: 80px;
    background: $gray;
    padding: 20px;
    color: $textCoin;
    z-index: 999999;
    border-radius: 12px;

    .slide-toggle {
        width: 34px;
        height: 18px;
        border: 1.5px solid $textCoin;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        left: 10px;
        top: 2px;

        .slide-ball {
            width: 11px;
            height: 11px;
            border-radius: 11px;
            position: absolute;
            top: 2px;
            left: 2px;
            background: $textCoin;
            transition: all ease-in-out .3s;

            &.on {
                left: 18px;
            }
        }
    }
}

.attention-wrapper {
    position: fixed;
    bottom: 380px;
    right: 34px;
    user-select: none;
    transition: .5s ease-in-out;
    opacity: 0;
    // z-index: 9999999999999999;

    .attention-badge {
        font-family: 'Montserrat', sans-serif;
        padding: 4px 18px;
        border-radius: 20px;
    }
}
.attention-wrapper-lower {
    bottom: 330px;
}

.pulse {
    animation: pulse 2s infinite;
}

.relative {
    position: relative;
}

.opponent-attention-wrapper {
    position: absolute;
    top: 15px;
    left: 0px;
    user-select: none;
    transition: .5s ease-in-out;
    opacity: 0;
    display: inline-block;

    .attention-badge {
        font-family: 'Montserrat', sans-serif;
        padding: 4px 18px;
        border-radius: 20px;
        animation: pulse 2s infinite;
        font-size: 12px;
    }
}

@keyframes pulse {
    0% { transform: scale(0.95); }
    25% { transform: scale(1.05); }
    50% { transform: scale(0.95); }
    75% { transform: scale(1.05); }
    100% { transform: scale(0.95); }
}

.click-dices {
    height: 50px;
    position: relative;
    top: -6px;
    padding-top: 6px;
}

.opacity-1 {
    opacity: 1 !important;
    z-index: 999999999999999;
}

.text-gray {
    color: $gray !important;
}

.cloth-dice-bg { background-position-y: 0px !important; }
.coin-dice-bg { background-position-y: -56px !important; }
.paper-dice-bg { background-position-y: -112px !important; }


.alchemist-scale {
    transform: scale(1.4);
    margin-right: 16px !important;
    margin-top: 8px;
}

.quick-game {
    color: #94D054;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    &:hover {
        text-decoration: underline;
    }
}

.hidden {
    display: none !important;
}
